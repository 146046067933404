import { select, takeEvery, put, take, delay } from 'redux-saga/effects';
import Wazo from '@wazo/sdk/lib/simple';
import uniq from 'lodash.uniq';

import {
  CONTACT_SEARCH_START,
  CONTACT_STATE_UPDATE_START,
  CONTACT_STATUS_UPDATE_START,
  CONTACT_UPDATE_DND,
  FETCH_OWN_CONTACT_START,
  FETCH_WAZO_SOURCE_SUCCESS,
  onCallLogContactsFetched,
  ownContactFetched,
  searchContactFailure,
  searchContactSuccess, userStatusUpdated,
  wazoSourceFetched,
} from '../actions/contactActions';
import { updateContactsWithStatuses, updateContactWithStatus } from '../utils/contactState';
import { bindEvents } from '../../main/utils/sagas';
import { FETCH_CALL_LOGS_SUCCESS } from '../../callLogs/actions/callLogActions';
import { MIN_SEARCH_LENGTH } from '../reducers/contactReducer';

const logger = Wazo.IssueReporter.loggerFor('contact-saga');

const contactEvents = {
  [Wazo.Websocket.CHATD_PRESENCE_UPDATED]: function* (payload) {
    yield onContactUpdated(payload.data);
  },
};

function* onContactUpdated(payload) {
  const {
    contact: { ownContact, searchResults, callLogContacts },
  } = yield select();
  const { uuid } = payload;

  logger.info('on contact updated', { uuid, ownUuid: ownContact.uuid });

  // Own contact updated
  if (payload.uuid === ownContact.uuid) {
    const contact = updateContactWithStatus(ownContact, payload);
    yield put(ownContactFetched(contact));
  }

  // Search result contact updated
  const searchIdx = searchResults.findIndex(contact => contact.uuid === uuid);
  if (searchIdx !== -1) {
    searchResults[searchIdx] = updateContactWithStatus(searchResults[searchIdx], payload);
    yield put(searchContactSuccess(searchResults));
  }

  // Call log contact updated
  if (uuid in callLogContacts) {
    callLogContacts[uuid] = updateContactWithStatus(callLogContacts[uuid], payload);
    yield put(onCallLogContactsFetched(callLogContacts));
  }
}

function* onFetchOwnContact() {
  // Wait for wazoSource to be fetched
  yield take(FETCH_WAZO_SOURCE_SUCCESS);

  // Give so time for the API to be updated with `line_state` value
  yield delay(500);

  const {
    user: { session },
  } = yield select();

  try {
    let contact = new Wazo.domain.Contact({ uuid: session.uuid });
    const status = yield Wazo.api.chatd.getContactStatusInfo(session.uuid);

    contact = updateContactWithStatus(contact, status);

    yield put(ownContactFetched(contact));

    yield bindEvents(contactEvents, Wazo.Websocket);
  } catch (e) {
    logger.error('fetch own contact, error', e);
  }
}

export function* fetchWazoSource() {
  const {
    user: { session },
  } = yield select();

  try {
    const result = yield Wazo.api.dird.fetchWazoSource(session.primaryContext());
    yield put(wazoSourceFetched(result.items[0]));
  } catch (e) {
    logger.error('fetch wazo source, error', e);
  }
}

function* onSearch({ payload }) {
  const {
    user: { session },
  } = yield select();

  const { query } = payload;

  logger.info('searching contact', { query });

  try {
    if (query.length < MIN_SEARCH_LENGTH) {
      yield put(searchContactSuccess([]));
      return;
    }

    let contacts = yield Wazo.api.dird.search(session.primaryContext(), query);
    const uuids = uniq(contacts.map(contact => contact && contact.uuid).filter(uuid => !!uuid));
    const statuses = yield Wazo.api.chatd.getMultipleLineState(uuids);

    contacts = updateContactsWithStatuses(contacts, statuses);

    yield put(searchContactSuccess(contacts));
  } catch (e) {
    yield put(searchContactFailure(e));
    logger.error('search contact, error', e);
  }
}

function* onUpdateUserState({ payload }) {
  const {
    user: { session },
  } = yield select();

  logger.info('update user state', payload);

  yield Wazo.api.chatd.updateState(session.uuid, payload.state);
}

function* onUpdateUserStatus({ payload }) {
  const {
    user: { session },
  } = yield select();

  logger.info('update user status', payload);

  yield Wazo.api.chatd.updateStatus(session.uuid, payload.state, payload.status);
  yield put(userStatusUpdated());
}

function* onUpdateUserDnd({ payload }) {
  const {
    user: { session },
  } = yield select();

  logger.info('update user dnd', payload);

  yield Wazo.api.confd.updateDoNotDisturb(session.uuid, payload.value);
}

function *fetchCallLogContacts() {
  const {
    callLogs: { callLogs },
    contact: { wazoSource },
    user: { session },
  } = yield select();
  try {
    const otherUuids = uniq(callLogs.map(callLog => callLog.theOtherParty(session).uuid).filter(uuid => !!uuid));
    let contacts = yield Wazo.api.dird.fetchWazoContacts(wazoSource, { uuid: otherUuids });
    const uuids = uniq(contacts.map(contact => contact && contact.uuid).filter(uuid => !!uuid));
    const statuses = yield Wazo.api.chatd.getMultipleLineState(uuids);

    contacts = updateContactsWithStatuses(contacts, statuses).reduce((acc, contact) => {
      acc[contact.uuid] = contact;
      return acc;
    }, {});

    yield put(onCallLogContactsFetched(contacts));
  } catch (e) {
    logger.error('fetch contact call logs, error', e);
  }
}

export default [
  takeEvery(CONTACT_SEARCH_START, onSearch),
  takeEvery(FETCH_OWN_CONTACT_START, onFetchOwnContact),
  takeEvery(CONTACT_STATE_UPDATE_START, onUpdateUserState),
  takeEvery(CONTACT_STATUS_UPDATE_START, onUpdateUserStatus),
  takeEvery(CONTACT_UPDATE_DND, onUpdateUserDnd),
  takeEvery(FETCH_CALL_LOGS_SUCCESS, fetchCallLogContacts),
];
