import {
  STORE_CARD_ID,
  SKIP_CARD,
  CREATE_OR_UPDATE_CARD_SUCCESS,
  CARD_SET_VALUE,
  UPDATE_CARD,
  UPDATE_FORM_SCHEMA,
  ON_OPTIONS_FETCHED,
  ON_OPTIONS_FOUND,
  SEARCH_OPTIONS,
  ON_SAVING_CARD,
  ON_SAVED_CARD,
} from '../actions/cardActions';
import { CALL_MADE, CALL_NUMBER, ON_INVITE, UPDATE_CALL_SESSION } from '../../call/actions/callActions';

const initialState = {
  cardId: null,
  savingCard: false,
  // The current callSession that is not trashed at the end of the call
  // useful to call `createOrUpdateTask` when the card is saved and the call ended.
  callSession: null,
  searching: {},
  options: {},
  foundOptions: {},
  content: {},
  schema: null,
  uiSchema: {
    note: { 'ui:widget': 'textarea' },
  },
};

const getResetState = state => ({
  ...initialState,
  schema: state.schema,
  uiSchema: state.uiSchema,
  options: state.options,
  foundOptions: state.foundOptions,
});

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case ON_INVITE:
    case CALL_MADE:
      return {
        ...getResetState(state),
        callSession: action.payload.callSession,
      };

    case UPDATE_CALL_SESSION:
      // Updating currentCallSession so we can have `answerTime` or `callId`
      if (!action.payload.callSession) {
        return state;
      }
      return { ...state, callSession: action.payload.callSession };

    case STORE_CARD_ID:
      return { ...state, cardId: action.payload.id };

    case ON_OPTIONS_FETCHED:
      return {
        ...state,
        options: {
          ...state.options,
          [action.payload.fieldId]: action.payload.options,
        },
      };

    case ON_OPTIONS_FOUND:
      return {
        ...state,
        foundOptions: {
          ...state.foundOptions,
          [action.payload.fieldId]: action.payload.options,
        },
        searching: {
          ...state.searching,
          [action.payload.fieldId]: false,
        },
      };

    case CALL_NUMBER:
    case SKIP_CARD:
    case CREATE_OR_UPDATE_CARD_SUCCESS:
      return getResetState(state);

    case CARD_SET_VALUE:
      return { ...state, content: { ...state.content, [action.payload.name]: action.payload.value } };

    case UPDATE_CARD:
      return { ...state, content: action.payload };

    case ON_SAVING_CARD:
      return { ...state, savingCard: true };

    case ON_SAVED_CARD:
      return { ...state, savingCard: false };

    case SEARCH_OPTIONS:
      return {
        ...state,
        searching: {
          ...state.searching,
          [action.payload.fieldId]: true,
        },
      };

    case UPDATE_FORM_SCHEMA:
      return {
        ...state,
        schema: action.payload.schema || state.schema,
        uiSchema: action.payload.uiSchema || state.uiSchema,
      };

    default:
      return state;
  }
}
