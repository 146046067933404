import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import Search from '@mui/icons-material/Search';
import Backspace from '@mui/icons-material/Backspace';
import Close from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';

import colors from '../../main/themes/colors';
import { MIN_SEARCH_LENGTH } from '../../contact/reducers/contactReducer';

const Container = styled.div`
  flex: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 80px;
  height: 80px;
  padding: 0 20px;
  margin-bottom: 4px;
  background: ${colors.secondGrey};

  &.active {
    padding: 0 10px 0 20px;
    margin-bottom: 0;
    border-bottom: 1px solid #ddd;
  }
`;

const InputWrapper = styled.div`
  flex: 1;
`;

const Input = styled.input`
  font-size: 32px;
  border: 0 none;
  outline: none;
  background: transparent;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
  &::placeholder {
    font-size: 26px;
    text-align: center;
  }
`;

const Button = styled(IconButton)`
  && {
    flex: 0;
    margin-right: 8px;

    & svg {
      width: .8em;
      height: .8em;
      fill: ${colors.secondary};
    }

    &.Mui-disabled {
      opacity: .3;
    }
  }
`;

const Keypad = ({ call, number, onChange, onSearch, onClose, placeholder, searching, displayingSearch, t }) => {
  const ref = useRef(null);
  const [isFocus, setIsFocus] = useState(false);

  const showEraser = number.length > MIN_SEARCH_LENGTH && !isFocus && !searching && !displayingSearch;
  const showSearch = number.length > 0 && onSearch;
  const showClose = displayingSearch;

  const containerClassName = classNames({
    'keypad-number-container': true,
    active: displayingSearch,
  });

  const handleFocus = event => setIsFocus(event.type === 'focus');

  useEffect(() => {
    if (ref.current) {
      ref.current.addEventListener('focus', handleFocus);
      ref.current.addEventListener('blur', handleFocus);
    }

    return () => {
      ref.current?.removeEventListener('focus', handleFocus);
      ref.current?.removeEventListener('blur', handleFocus);
    };
  }, [ref.current]);

  return (
    <Container className={containerClassName}>
      <InputWrapper>
        <Input
          ref={ref}
          className="keypad-number"
          id="searchTextField"
          onChange={e => onChange(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              if (number.match(/[A-zÀ-ú\s]+/)) {
                onSearch();
              } else {
                call(number);
              }
            }
          }}
          placeholder={isFocus ? '' : placeholder || t('searchInWazo')}
          type="text"
          value={number}
        />
      </InputWrapper>

      {showEraser && (
        <Button className="keypad-eraser" onClick={() => onChange(number.slice(0, -1))} size="small">
          <Backspace />
        </Button>
      )}

      {showSearch && (
        <Button className="keypad-search" disabled={searching} onClick={onSearch} size="small">
          <Search />
        </Button>
      )}

      {showClose && (
        <Button onClick={onClose}>
          <Close />
        </Button>
      )}

    </Container>
  );
};
export default React.memo(withTranslation('call')(Keypad));
