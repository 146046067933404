/* globals sforce */
import qs from 'query-string';
import moment from 'moment';

import { INCOMING } from '../../call/reducers/callReducer';
import { parseTemplate } from '../../main/utils/string';

import { parseEntity } from './utils';

export const displayPanel = () => sforce.opencti.setSoftphonePanelVisibility({ visible: true });

let salesforceConfig;

export const setConfig = config => {
  salesforceConfig = config;
};

const callApex = (name, params) => new Promise((resolve, reject) => {
  sforce.opencti.runApex({
    apexClass: 'WazoPhoneHelper',
    methodName: name,
    methodParams: qs.stringify(params, { encode: false }),
    callback: response => {
      if (!response.success) {
        return reject(response.errors[0]);
      }

      return resolve(response.returnValue.runApex);
    },
  });
});

export const getCallCenterSettings = () =>
  new Promise((resolve, reject) => {
    sforce.opencti.getCallCenterSettings({
      callback: result => {
        if (!result.success) {
          return reject(result);
        }

        return resolve({
          server: result.returnValue['/wazoServerSettings/wazoServerAddress'],
          port: result.returnValue['/wazoServerSettings/wazoServerPort'],
          language: result.returnValue['/wazoServerSettings/wazoLanguage'],

          wrapUpDuration: result.returnValue['/wazoCardForm/wrapUpDuration'],
          taskSubject: result.returnValue['/wazoCardForm/taskSubject'],
          taskDescription: result.returnValue['/wazoCardForm/taskDescription'],
          schema: result.returnValue['/wazoCardForm/schema'],
          uiSchema: result.returnValue['/wazoCardForm/uiSchema'],
        });
      },
    });
  });

export const searchContact = number => callApex('searchContact', { phone: number });

export const searchAndPopContact = (number, direction, deferred = false) => new Promise((resolve, reject) => {
  return sforce.opencti.searchAndScreenPop({
    searchParams: number,
    callType: direction,
    deferred,
    callback: data => {
      if (!data.success) {
        reject(data.errors);
        return;
      }

      // Return only the 30 first results
      const results = Object.keys(data.returnValue).slice(0, 30).reduce((acc, key) => {
        acc[key] = data.returnValue[key];
        return acc;
      }, {});

      resolve(results);
    },
  });
});

export const createContact = async formData => {
  const result = await callApex('createContact', formData);
  result.Name = `${result.FirstName} ${result.LastName}`;
  result.RecordType = 'Contact';

  return parseEntity(result);
};

export const createContactFromCallSession = async callSession => {
  // Create the contact if not exist (when called from an SDA, we don't have a callerName)
  const namePart = callSession.displayName ? callSession.displayName.split(' ') : ['-', '-'];

  return createContact(callSession.number, namePart[0] || '-', namePart[1] || '-');
};

export const getTaskFields = (callSession, callDirection, toNumber, card) => {
  const durationInSeconds = callSession.answerTime ?
    Math.floor((callSession.endTime - callSession.answerTime || 0) / 1000) : 0;
  const exceedsOneHour = durationInSeconds > 60 * 60;
  const duration = moment.utc(durationInSeconds * 1000).format(exceedsOneHour ? 'HH:mm:ss' : 'mm:ss');
  const direction = callDirection === INCOMING ? 'inbound' : 'outbound';
  const fromNumber = callSession.number;

  return {
    durationInSeconds,
    duration,
    direction,
    fromNumber,
    toNumber,
    title: card.title,
    note: card.note,
    callDate: callSession.creationTime.toISOString(),
    answerDate: callSession.answerTime ? callSession.answerTime.toISOString() : null,
    endDate: callSession.endTime.toISOString(),
    externalNumber: callDirection === INCOMING ? fromNumber : toNumber,
    callId: callSession.sipCallId,
    whoName: card.whoId.Name,
    whatName: card.whoId?.Name,
  };
};

export const getParsedTitle = (fields, card) => {
  const defaultValue = card.title || '';
  if (salesforceConfig.taskSubject) {
    return parseTemplate(salesforceConfig.taskSubject, fields) || defaultValue;
  }

  return defaultValue;
};

export const getParsedNote = (fields, card) => {
  const defaultValue = card.note || '';

  if (salesforceConfig.taskDescription) {
    return parseTemplate(salesforceConfig.taskDescription, fields) || defaultValue;
  }

  return defaultValue;
};

export const getParsedSubject = (fields, card) =>
  parseTemplate(salesforceConfig.taskSubject, fields) || card.subject || '';

export const getParsedDescription = (fields, card) =>
  parseTemplate(salesforceConfig.taskDescription, fields) || card.description || '';

export const createLogCall = (callSession, callDirection, toNumber, card) => {
  const { whoId, whatId } = card;
  const fields = getTaskFields(callSession, callDirection, toNumber, card);

  let methodName = !!whoId ? 'logContactCall' : 'logNonContactCall';
  let ids = { entityId: whoId ? whoId.Id : whatId.Id };
  if (whoId?.Id && whatId?.Id) {
    methodName = 'logCallForWhoAndWhat';
    ids = {
      WhoId: whoId.Id,
      WhatId: whatId.Id,
    };
  }

  return callApex(methodName, {
    ...ids,
    ...fields,
    subject: getParsedTitle(fields, card),
    description: getParsedNote(fields, card),
  });
};

export const updateCallLog = (callSession, callDirection, toNumber, task) => {
  const { whoId, whatId, cardId } = task;
  const fields = getTaskFields(callSession, callDirection, toNumber, task);

  return callApex('updateCallLog', {
    logId: cardId,
    subject: getParsedTitle(fields, task),
    description: getParsedNote(fields, task),
    // Can't send null, or we'll get a `Missing Method Parameter xxx.`
    whoId: whoId?.Id || '',
    whatId: whatId?.Id || '',
  });
};

export const displayRecord = recordId => {
  sforce.opencti.screenPop({ type: sforce.opencti.SCREENPOP_TYPE.SOBJECT, params: { recordId } });
};

export const refreshView = () => sforce.opencti.refreshView();
