export default {
  userState: 'Status',
  title: 'Settings',
  line: 'Line',
  language: 'Language',
  information: 'Information',
  logout: 'Logout',
  french: 'French',
  english: 'English',
  communicationServer: 'Server address',
  appVersion: 'Application version',
  currentNumber: 'Phone number',
  licenses: 'Licenses',
  callForwarding: 'Call Forwarding',
  save: 'Save',
  unconditional: 'Unconditional',
  noAnswer: 'No answer',
  busy: 'Busy',
};
