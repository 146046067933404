import {
  FETCH_CALL_LOGS_START,
  FETCH_CALL_LOGS_SUCCESS,
  FETCH_CALL_LOGS_FAILED,
  CALL_LOG_CREATED,
} from '../actions/callLogActions';

const initialState = {
  fetching: false,
  callLogs: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_CALL_LOGS_START:
      return { ...state, fetching: true };

    case FETCH_CALL_LOGS_FAILED:
      return { ...state, fetching: false };

    case FETCH_CALL_LOGS_SUCCESS:
      return { ...state, callLogs: action.payload.callLogs, fetching: false };

    case CALL_LOG_CREATED:
      return { ...state, callLogs: [...state.callLogs, action.payload.callLog].sort((a, b) => b.start - a.start) };

    default:
      return state;
  }
}
