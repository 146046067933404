import { createSelector } from 'reselect';

const getForwards = (state) => state.user.session?.profile?.forwards || [];

const unconditionalCallForwardSelector = state => getForwards(state).find(option => option.key === 'unconditional');
export const getUnconditionalCallForward = createSelector(
  unconditionalCallForwardSelector,
  forwardOption => forwardOption,
);

const noAnswerCallForwardSelector = state => getForwards(state).find(option => option.key === 'noanswer');
export const getNoAnswerCallForward = createSelector(
  noAnswerCallForwardSelector,
  forwardOption => forwardOption,
);

const busyCallForwardSelector = state => getForwards(state).find(option => option.key === 'busy');
export const getBusyCallForward = createSelector(
  busyCallForwardSelector,
  forwardOption => forwardOption,
);
