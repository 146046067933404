import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';
import { MuiForm5 as Form } from '@rjsf/material-ui';

import { saveCard, skipCard, updateCard, displayLinkedOption } from '../actions/cardActions';

import Autocomplete from './Autocomplete';

const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5px;
`;

const FormButton = styled(Button)`
  && {
    display: block;
    margin: 0 10px;
    border-radius: 28px;
  }
`;

const CancelButton = styled(FormButton)``;

const SaveButton = styled(FormButton)``;

const Buttons = ({ actions, ended, t, savingCard }) => {
  if (!ended) {
    return null;
  }

  return (
    <ButtonsContainer>
      <CancelButton onClick={actions.skipCard} variant="outlined">
        {t('cancel')}
      </CancelButton>
      <SaveButton disabled={savingCard} type="submit" variant="contained">
        {t('save')}
      </SaveButton>
    </ButtonsContainer>
  );
};

const Card = ({
  cardCallSession,
  actions,
  ringing,
  ended,
  content,
  savingCard,
  t,
  wrapUpDuration,
  schema,
  uiSchema,
}) => {
  const [formData, setFormData] = useState(content);
  const wrapUpTimeout = useRef(null);

  useEffect(() => {
    if (!ended || formData.title || formData.note || !wrapUpDuration || +wrapUpDuration === 0) {
      return;
    }
    wrapUpTimeout.current = setTimeout(() => {
      actions.skipCard();
    }, +wrapUpDuration * 1000);
  }, [ended]);

  useEffect(() => {
    setFormData(content);
  }, [content]);

  useEffect(() => {
    content.phoneNumber = cardCallSession.number;
    content.callId = cardCallSession.sipCallId;
    setFormData(content);
  }, []);

  const validate = (validateFormData, errors) => {
    const requiredAutocompleteFields = schema.required.filter(field =>
      field in uiSchema && uiSchema[field]['ui:field'] === 'autocomplete');

    requiredAutocompleteFields.forEach(field => {
      if (!Object.keys(validateFormData[field]).length) {
        errors[field].addError('Required field');
      }
    });

    return errors;
  };

  const performSubmit = ({ formData: submittedFormData }) => {
    return actions.saveCard(submittedFormData, cardCallSession);
  };

  const onChange = ({ formData: newFormData }) => {
    actions.updateCard(newFormData);
    setFormData(newFormData);

    if (wrapUpTimeout.current) {
      clearTimeout(wrapUpTimeout.current);
    }
  };

  if (!schema || ringing) {
    return null;
  }

  const fields = {
    autocomplete: props => (
      <Autocomplete
        {...props}
        createForm={props.schema.createForm}
        error={props.rawErrors && props.rawErrors.length > 0}
        label={`${props.schema.title}${props.required ? ' *' : ''}`}
        onChange={option => {
          if (option && option.id && props.schema.triggerDisplay) {
            actions.displayLinkedOption(option.id);
          }
          props.onChange(option);
        }}
        value={props.formData && Object.keys(props.formData).length ? props.formData : null}
      />
    ),
  };

  return (
    <StyledForm
      className="card-form"
      fields={fields}
      formData={formData}
      noHtml5Validate
      onChange={onChange}
      onSubmit={performSubmit}
      schema={schema}
      showErrorList={false}
      uiSchema={uiSchema}
      validate={validate}
    >
      <Buttons actions={actions} ended={ended} savingCard={savingCard} t={t} />
    </StyledForm>
  );
};

const mapStateToProps = state => ({
  currentCallSession: state.call.currentCallSession,
  authenticating: state.user.authenticating,
  error: state.user.error,
  ended: state.call.ended,
  ringing: state.call.ringing,
  content: state.card.content,
  cardCallSession: state.card.callSession,
  savingCard: state.card.savingCard,
  wrapUpDuration: state.main.integrationConfig?.wrapUpDuration,
  schema: state.card.schema,
  uiSchema: state.card.uiSchema,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ skipCard, saveCard, updateCard, displayLinkedOption }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(withTranslation('card')(Card)));
