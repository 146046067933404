export default {
  callHistory: 'Calls',
  voicemails: 'Voicemails',
  loadingHistory: 'Loading history',
  emptyStateCalls: 'Place your first call, you will see it here.',
  today: 'today',
  yesterday: 'yesterday',

  calls: 'Calls',
  unknown: 'Unknown',
  incomingCallLog: 'Incoming call',
  outgoingCall: 'Outgoing call',
  missedOutgoingCall: 'Canceled call',
  missedCall: 'Missed call',
  declinedCall: 'Declined call',

  deleteVoicemail: 'Delete',
  download: 'Download',

  emptyStateVoicemails: 'You do not have any voicemail.',
};
