/* eslint-disable import/no-unresolved */

import * as React from 'react';
import styled from 'styled-components';
import Typography from '@mui/material/Typography/Typography';
import { withTranslation } from 'react-i18next';

import packageJson from '../../../package.json';
import rawLicenses from '../../licenses.json';
import metrics from '../../main/themes/metrics';

const licenses = rawLicenses.reduce((map, dep) => {
  const nameParts = dep.name.split('@');
  nameParts.pop();
  map[nameParts.join('@')] = dep;

  return map;
}, {});

const Container = styled.div`
  padding: ${metrics.spacing.big}px ${metrics.spacing.large}px;
  overflow-y: scroll;
`;

const Title = styled(Typography)`
  && {
    margin-bottom: 30px;
    text-align: center;
    font-size: 30px;
  }
`;

const Text = styled.p`
  color: rgba(0, 0, 0, 0.54);
`;

const Licenses = ({ t }) => (
  <Container data-testid="licenses-root">
    <Title className="licenses-title" component="h1">
      {t('licenses')}
    </Title>

    {Object.keys(packageJson.dependencies).map(dependencyName => (
      <Text key={dependencyName}>
        {dependencyName}
        {' '}
        :
        {' '}
        {dependencyName in licenses ? licenses[dependencyName].licenses : 'n/a'}
      </Text>
    ))}
  </Container>
);

export default withTranslation('settings')(Licenses);
