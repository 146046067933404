import Wazo from '@wazo/sdk/lib/simple';
import { put, select, takeEvery } from 'redux-saga/effects';
import CallLog from '@wazo/sdk/lib/domain/CallLog';

import {
  onCallLogCreated,
  onCallLogsFetched,
  onCallLogsFetchFailed,
  FETCH_CALL_LOGS_START,
} from '../actions/callLogActions';
import { bindEvents } from '../../main/utils/sagas';

const CALL_LOGS_LIMIT = 100;
const logger = Wazo.IssueReporter.loggerFor('call-log-saga');

const callLogsEvents = {
  [Wazo.Websocket.CALL_LOG_USER_CREATED]: function* (payload) {
    yield onWsCallLogCreated(payload.data);
  },
};

function* onWsCallLogCreated(payload) {
  const { user: { session } } = yield select();

  logger.info('on call log created via WS', { callLog: payload });

  yield put(onCallLogCreated(CallLog.parseNew(payload, session)));
}

function *onFetchCallLogs() {
  try {
    const callLogs = yield Wazo.api.callLogd.listCallLogs(0, CALL_LOGS_LIMIT);
    yield put(onCallLogsFetched(callLogs));
  } catch (e) {
    yield put(onCallLogsFetchFailed());
    logger.error('fetch call logs, error', e);
  }

  yield bindEvents(callLogsEvents, Wazo.Websocket);
}

export default [
  takeEvery(FETCH_CALL_LOGS_START, onFetchCallLogs),
];
