import { combineReducers } from 'redux';

import user from '../../user/reducers/userReducer';
import dialer from '../../dialer/reducers/dialerReducer';
import call from '../../call/reducers/callReducer';
import card from '../../card/reducers/cardReducer';
import agent from '../../agent/reducers/agentReducer';
import contact from '../../contact/reducers/contactReducer';
import callLogs from '../../callLogs/reducers/callLogReducer';
import voicemails from '../../voicemails/reducers/voicemailReducer';

import main from './mainReducer';

export const initialState = {
  main,
  user,
  dialer,
  call,
  card,
  agent,
  contact,
  callLogs,
  voicemails,
};

let reducerCustomizer = state => state;

export const registerReducerCustomizer = fn => {
  reducerCustomizer = fn;
};

export default () => (state, action) => combineReducers(reducerCustomizer(initialState))(state, action);
