export default {
  callHistory: 'Appels',
  voicemails: 'Messages vocaux',
  loadingHistory: 'Chargement de l\'historique',
  emptyStateCalls: 'Initiez votre premier appel, vous le verrez apparaître ici.',

  today: 'aujourd\'hui',
  yesterday: 'hier',

  calls: 'Appels',
  unknown: 'Inconnu',
  incomingCallLog: 'Appel reçu',
  outgoingCall: 'Appel émis',
  missedOutgoingCall: 'Appel annulé',
  missedCall: 'Appel manqué',
  declinedCall: 'Appel refusé',

  deleteVoicemail: 'Supprimer',
  download: 'Télécharger',

  emptyStateVoicemails: 'Vous n\'avez aucun message vocal.',
};
