import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translations from './translations';
import { getLocalStorageItem } from './main/utils/localstorage';

const defaultLanguage = (getLocalStorageItem('language') || 'en').toLowerCase();

i18n
  .use({
    type: 'postProcessor',
    name: 'debug',
    process: function (value, key, options) {
      return `${options.ns}:${key[0]}`;
    },
  })
  .use(initReactI18next)
  .init({
    resources: translations,
    fallbackLng: defaultLanguage,
    lng: defaultLanguage,
    interpolation: { escapeValue: false },
    saveMissing: true,
  });

export const getTranslator = namespace => i18n.getFixedT(i18n.language, namespace);

export default i18n;
