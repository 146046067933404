import React, { useState } from 'react';
import MuiAutocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Modal from '@mui/material/Modal';

import { searchOptions } from '../actions/cardActions';
import { parseTemplate } from '../../main/utils/string';

import NewOptionForm from './NewOptionForm';

const Container = styled(FormControl)`
  && {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding-top: 0;
  }
`;

const StyledAutocomplete = styled(MuiAutocomplete)`
  margin: 0;
  padding-top: 0;
  flex: 1;
`;

export const NEW_OPTION_VALUE = 'new-option';

const Autocomplete = ({
  actions,
  createForm,
  error,
  label,
  name,
  searching,
  onChange,
  options,
  foundOptions,
  value: rawValue,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [newOption, setNewOption] = useState(null);
  const [inputValue, setInputValue] = useState('');
  // Avoid sending `undefined` to value or the Autocomplete will be considered as uncontrolled.
  const [value, setValue] = useState(rawValue || null);

  const onInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);

    if (newInputValue && newInputValue.length > 2) {
      actions.searchOptions(newInputValue, name);
    }
  };

  const onChangeValue = (event, newValue) => {
    setValue(newValue);
    onChange(newValue);
  };

  const onAddOption = (formData) => {
    setModalOpen(false);
    const option = {
      ...formData,
      wazoId: NEW_OPTION_VALUE,
      label: parseTemplate(createForm.optionLabel, formData),
    };

    setNewOption(option);
    setValue(option);
    onChange(option);
  };

  const computedOptions = [...options, ...foundOptions];
  if (newOption) {
    computedOptions.push(newOption);
  }

  return (
    <Container>
      <StyledAutocomplete
        inputValue={inputValue}
        label={label}
        onChange={onChangeValue}
        onInputChange={onInputChange}
        options={computedOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {searching ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
            label={label}
          />
        )}
        renderOption={(props, option) => (
          <li {...props} key={option.id || option.label}>
            {option.label}
          </li>
        )}
        value={value}
      />
      {createForm && (
        <IconButton onClick={() => setModalOpen(true)}>
          <AddIcon />
        </IconButton>
      )}
      <Modal onClose={() => setModalOpen(false)} open={modalOpen}>
        <div>
          <NewOptionForm form={createForm} onAddOption={onAddOption} setModalOpen={setModalOpen} />
        </div>
      </Modal>
    </Container>
  );
};

const mapStateToProps = (state, ownProps) => ({
  options: state.card.options[ownProps.name] || [],
  foundOptions: state.card.foundOptions[ownProps.name] || [],
  searching: state.card.searching[ownProps.name],
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ searchOptions }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(Autocomplete));
