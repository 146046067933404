import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography/Typography';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import CallIcon from '@mui/icons-material/Call';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import i18n from 'i18next';

import List from '../../main/components/List';
import Initials from '../../contact/domain/Initials';
import StateIndicator from '../../contact/containers/StateIndicator';
import Header from '../../main/components/Header';
import colors from '../../main/themes/colors';

const Container = styled.div`
  height: calc(100% - 70px);
  overflow-y: scroll;
`;

const Empty = styled(Typography)`
  padding: 30px 15px;
  text-align: center;
`;

const StyledAvatar = styled(Avatar)`
  background-color: ${colors.primary} !important;
`;

const CallButton = styled(IconButton)`
  margin-right: 15px !important;
`;

const CallLogs = ({ callLogs, session, contacts, t }) => {
  const navigate = useNavigate();

  if (!callLogs.length) {
    return <Empty>{t('emptyCallLogs')}</Empty>;
  }

  return (
    <Container className="call-logs">
      <Header label={t('callLogs')} />

      <List>
        {callLogs.map(callLog => {
          const otherParty = callLog.theOtherParty(session);
          const otherUuid = otherParty?.uuid;
          const contact = otherUuid in contacts ? contacts[otherUuid] : {
            name: otherParty.name,
            number: otherParty.extension,
            emails: [],
          };

          return (
            <ListItem key={callLog.id}>
              {otherUuid in contacts && (<StateIndicator contact={contact} />)}
              <ListItemAvatar>
                <StyledAvatar>
                  {Initials.parseFromContact(contact)}
                </StyledAvatar>
              </ListItemAvatar>
              <ListItemText
                className="call-log"
                primary={`${contact.name ? `${contact.name} - ` : ''} ${contact.number}`}
                secondary={moment.utc(callLog.start).locale(i18n.language).fromNow()}
              />
              <ListItemSecondaryAction>
                <CallButton
                  color="secondary"
                  edge="end"
                  onClick={() => navigate(`/dialer?call=${encodeURIComponent(contact.number)}`)}
                >
                  <CallIcon />
                </CallButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </Container>
  );
};

const mapStateToProps = state => ({
  session: state.user.session,
  callLogs: state.callLogs.callLogs,
  contacts: state.contact.callLogContacts,
  updatedAt: state.contact.updatedAt,
});

export default connect(mapStateToProps)(React.memo(withTranslation('callLogs')(CallLogs)));
