import React from 'react';
import { CircularProgress, Typography } from '@mui/material';
import styled from 'styled-components';

import colors from '../../../main/themes/colors';

const Centered = styled.div`
  flex: 1;
  max-height: 100vh;
  display: flex;
  align-items: stretch;
  justify-content: center;
  text-align: center;
`;

const StyledTypography = styled(Typography)`
  display: block;
  margin-top: 40px;
  color: ${colors.disabled};
`;

const Holder = styled.div`
  flex: 1;
  align-self: center;
  text-align: center;
`;

const StyledCircularProgress = styled(CircularProgress)`
  color: ${colors.disabled};
`;

const ActivityIndicator = ({ label, iconOnly, id, size = 20, ...props }) => {
  const icon = <StyledCircularProgress size={size} />;

  const content = (
    <Holder id={id}>
      {icon}
      <br />
      <StyledTypography variant="caption">{label}</StyledTypography>
    </Holder>
  );

  return <Centered {...props}>{content}</Centered>;
};

export default ActivityIndicator;
