import i18n from 'i18next';
import merge from 'lodash.merge';

export const overrideTranslations = translations => {
  i18n.options.resources = merge(i18n.options.resources, translations);
};

export const enableTranslationDebug = () => {
  i18n.init({
    postProcess: ['debug'],
  });
};
