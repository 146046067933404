import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';

import sagas from './sagas';

import getReducers from '.';

const sagaMiddleware = createSagaMiddleware();

const configureStore = () => {
  const enhancers = compose(applyMiddleware(sagaMiddleware));

  const store = createStore(getReducers(), enhancers);
  sagaMiddleware.run(sagas);

  if (module.hot) {
    module.hot.accept(() => {
      const nextRootReducer = reducer;
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};

export default configureStore;
