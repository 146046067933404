export const DISPLAY_ERROR = 'main/DISPLAY_ERROR';
export const HIDE_ERROR = 'main/HIDE_ERROR';
export const UPDATE_LANGUAGE = 'main/UPDATE_LANGUAGE';
export const ENABLE_AGENT = 'main/ENABLE_AGENT';
export const OVERRIDE_THEMES = 'main/OVERRIDE_THEMES';
export const OVERRIDE_ASSETS = 'main/OVERRIDE_ASSETS';

export const enableAgent = () => ({ type: ENABLE_AGENT });

export const updateLanguage = language => ({ type: UPDATE_LANGUAGE, payload: { language } });

export const displayError = error => ({ type: DISPLAY_ERROR, error });

export const hideError = () => ({ type: HIDE_ERROR });

export const overrideThemes = themes => ({ type: OVERRIDE_THEMES, themes });

export const overrideAssets = assets => ({ type: OVERRIDE_ASSETS, assets });
