export default {
  title: 'Paramètres',
  line: 'Ligne',
  language: 'Langue',
  information: 'Informations',
  logout: 'Déconnexion',
  french: 'Français',
  english: 'Anglais',
  communicationServer: 'Serveur',
  appVersion: 'Numéro de l\'application',
  buildVersion: 'Numéro de version',
  userState: 'Statut',
  licenses: 'Licences',
  callForwarding: 'Renvoi d\'appel',
  save: 'Enregistrer',
  unconditional: 'Inconditionnel',
  noAnswer: 'Pas de réponse',
  busy: 'Occupé',
};
