export const TOGGLE_AUTO_LOGIN = 'user/TOGGLE_AUTO_LOGIN';
export const AUTH_WITH_TOKEN_REQUEST = 'user/AUTH_WITH_TOKEN_REQUEST';
export const LOGIN_REQUEST = 'user/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'user/LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'user/LOGIN_FAILURE';
export const AUTHENTICATION_REQUEST = 'user/AUTHENTICATION_REQUEST';
export const AUTHENTICATION_SUCCESS = 'user/AUTHENTICATION_SUCCESS';
export const AUTHENTICATION_FAILURE = 'user/AUTHENTICATION_FAILURE';
export const IS_AUTHENTICATING = 'user/IS_AUTHENTICATING';
export const ON_CHANGE_REQUIRED_SUBSCRIPTION_TYPE = 'user/ON_CHANGE_REQUIRED_SUBSCRIPTION_TYPE';
export const LOGOUT_REQUEST = 'user/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'user/LOGOUT_SUCCESS';
export const UPDATE_PROFILE = 'user/UPDATE_PROFILE';
export const SEND_FORWARD_OPTIONS = 'user/SEND_FORWARD_OPTIONS';
export const SET_APP_CONFIGURATION = 'user/SET_APP_CONFIGURATION';

export const toggleAutoLogin = disabled => ({ type: TOGGLE_AUTO_LOGIN, payload: { disabled } });

export const login = (username, password, useLdap, domainName) => ({
  type: LOGIN_REQUEST,
  payload: { username, password, useLdap, domainName },
});

export const authenticateUserWithToken = (token, refreshToken) => ({
  type: AUTH_WITH_TOKEN_REQUEST,
  payload: { token, refreshToken },
});

export const logout = () => ({
  type: LOGOUT_REQUEST,
});

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});

export const loginSuccess = session => ({
  type: LOGIN_SUCCESS,
  payload: { session },
});

export const loginFailed = (error, technicalError) => ({
  type: LOGIN_FAILURE,
  payload: { error, technicalError },
});

export const authenticate = () => ({
  type: AUTHENTICATION_REQUEST,
});

export const authenticationSuccess = session => ({
  type: AUTHENTICATION_SUCCESS,
  payload: { session },
});

export const authenticationFailed = (error, technicalError) => ({
  type: AUTHENTICATION_FAILURE,
  payload: { error, technicalError },
});

export const setIsAuthenticating = () => ({
  type: IS_AUTHENTICATING,
});

export const changeRequiredSubscriptionType = subscriptionType => ({
  type: ON_CHANGE_REQUIRED_SUBSCRIPTION_TYPE,
  payload: { subscriptionType },
});

export const updateForwardOption = forwardOption => async (dispatch, getState) => {
  const profile = getState().user.session?.profile;

  const newProfile = profile && profile.setForwardOption && profile.setForwardOption(forwardOption);
  newProfile &&
  dispatch({
    type: UPDATE_PROFILE,
    profile: Profile.newFrom(newProfile),
  });
};

export const sendForwardOption = forwardOption => ({ type: SEND_FORWARD_OPTIONS, forwardOption });
