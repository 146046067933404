import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CircularProgress from '@mui/material/CircularProgress';
import classNames from 'classnames';

import { searchContact } from '../../contact/actions/contactActions';
import KeypadNumber from '../components/KeypadNumber';
import Keys from '../components/Keys';
import SearchResults from '../../contact/components/SearchResults';
import colors from '../../main/themes/colors';

const Container = styled.div`
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ transfer }) => transfer ? '-10px' : 0};
`;

const Progress = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const KeypadNumberWrapper = styled.div`
  &.active {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2000;
    width: 100%;
    display: flex;
    flex-direction: column;

    > div {
      border-radius: 0;
    }
  }

  &.hasContent {
    background: #eee;
    height: 100%;

    li {
      .MuiListItemText-primary {
        font-weight: bold;
      }

      .MuiListItemText-secondary {
        color: rgba(0, 0, 0, 0.6);
        font-size: 0.8em;
      }
      
      .MuiListItem-root {
        border-color: #ddd;
      }

      button {
        background-color: #ddd;

        svg {
          fill: ${colors.button};
        }
        
        &:hover {
          background-color: ${colors.call};

          svg {
            fill: #fff;
          }
        }

      }
    }
  }
`;

const ResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Keypad = ({
  customCallMethod,
  transfer,
  actions,
  searchResults,
  searching,
  call,
  number,
  onChange,
  placeholder,
  sendDTMF,
  SearchResultsButtons,
  getDisplaySearchMethod,
}) => {
  const [displaySearch, setDisplaySearch] = useState(false);

  const inputWrapperClasses = classNames({
    active: displaySearch,
    hasContent: displaySearch,
  });

  const onChangeNumber = value => {
    onChange(value);
    if (displaySearch) {
      actions.searchContact(value);
    }
  };

  const onSearch = () => {
    setDisplaySearch(true);
    actions.searchContact(number);
  };

  const onClose = () => setDisplaySearch(false);

  useEffect(() => {
    if (getDisplaySearchMethod) {
      getDisplaySearchMethod(setDisplaySearch);
    }
  }, []);

  return (
    <Container transfer={transfer}>
      <KeypadNumberWrapper className={inputWrapperClasses}>
        <KeypadNumber
          call={customCallMethod || call}
          displayingSearch={displaySearch}
          number={number}
          onChange={onChangeNumber}
          onClose={onClose}
          onSearch={onSearch}
          placeholder={placeholder}
          searching={searching}
        />

        {displaySearch && (
          <ResultsContainer>
            {searching ? (
              <Progress><CircularProgress size={50} /></Progress>
            ) : (
              <SearchResults
                full
                results={searchResults}
                SearchResultsButtons={SearchResultsButtons}
              />
            )}
          </ResultsContainer>
        )}
      </KeypadNumberWrapper>

      <Keys number={number} onChange={onChangeNumber} sendDTMF={sendDTMF} />
    </Container>
  );
};

const mapStateToProps = state => ({
  searchResults: state.contact.searchResults,
  searching: state.contact.searching,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    searchContact,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(Keypad));
