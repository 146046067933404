export const getEntityLabel = entity => {
  if (!entity) {
    return 'N/A';
  }

  switch (entity.RecordType) {
    case 'Contact':
    case 'Lead':
      return `${entity.Name} (${entity.RecordType})`;
    case 'Account':
      return `${entity.Name} (${entity.RecordType})`;
    case 'Case':
      return `${entity.Subject || entity.Description || entity.CaseNumber} (${entity.RecordType})`;
    default:
      return `${entity.Name || entity.Id} (${entity.RecordType})`;
  }
};

export const parseEntity = entity => ({
  id: entity.Id,
  wazoId: entity.Id,
  wazoLabel: getEntityLabel(entity),
  label: getEntityLabel(entity),
  human: entity.RecordType === 'Contact' || entity.RecordType === 'Lead',
  ...entity,
});

export const parseEntities = entities => entities.map(parseEntity);
