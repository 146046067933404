import Wazo from '@wazo/sdk/lib/simple';
import { put, takeEvery } from 'redux-saga/effects';
import Voicemail from '@wazo/sdk/lib/domain/Voicemail';

import {
  onVoicemailsFetchFailed,
  onVoicemailsFetched,
  onVoicemailCreated,
  onVoicemailDeleted,
  FETCH_VOICEMAILS_START,
  DELETE_VOICEMAIL_REQUEST,
} from '../actions/voicemailActions';
import { bindEvents } from '../../main/utils/sagas';

const logger = Wazo.IssueReporter.loggerFor('voicemail-saga');

const voicemailsEvents = {
  [Wazo.Websocket.USER_VOICEMAIL_MESSAGE_CREATED]: function* (payload) {
    yield onWsVoicemailCreated(payload.data);
  },
  [Wazo.Websocket.USER_VOICEMAIL_MESSAGE_DELETED]: function* (payload) {
    yield onWsVoicemailDeleted(payload.data);
  },
};

function* onWsVoicemailCreated(payload) {
  const voicemail = payload.message;
  logger.info('on voicemail created via WS', { voicemail });

  yield put(onVoicemailCreated(Voicemail.parse(voicemail)));
}

function* onWsVoicemailDeleted(payload) {
  const voicemail = payload.message;
  logger.info('on voicemail deleted via WS', { voicemail });

  yield put(onVoicemailDeleted(Voicemail.parse(voicemail)));
}

function *onFetchVoicemails() {
  try {
    const voicemails = yield Wazo.api.calld.listVoicemails();
    yield put(onVoicemailsFetched(voicemails));
  } catch (e) {
    yield put(onVoicemailsFetchFailed());
    logger.error('fetch voicemail, error', e);
  }

  yield bindEvents(voicemailsEvents, Wazo.Websocket);
}

function *onDeleteVoicemail({ payload: { voicemail } }) {
  logger.info('deleting voicemail', { voicemail });

  yield Wazo.api.calld.deleteVoicemail(voicemail.id);
  yield put(onVoicemailDeleted(voicemail));
}

export default [
  takeEvery(FETCH_VOICEMAILS_START, onFetchVoicemails),
  takeEvery(DELETE_VOICEMAIL_REQUEST, onDeleteVoicemail),
];
