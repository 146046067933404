import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import Button from '@mui/material/Button';
import { withTranslation } from 'react-i18next';

import { directTransfer, indirectTransfer, cancelIndirectTransfer, confirmIndirectTransfer } from '../../call/actions/callActions';

import Keypad from './Keypad';

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
`;

const TransferButton = styled(Button)`
  && {
    display: block;
    width: 120px;
    margin: 0 auto;
    border-radius: 28px;
  }
`;

const ResultsWrapper = styled.div`
  width: 250px;
  & button {
    width: auto !important;
  }
`;

const SearchResultsButtons = ({ contact: { number }, triggerDirectTransfer, triggerIndirectTransfer, t }) => (
  <ButtonsContainer>
    <TransferButton data-testid="directTransferButton" disabled={!number} onClick={() => triggerDirectTransfer(number)} variant="contained">
      {t('directTransfer')}
    </TransferButton>

    <TransferButton data-testid="indirectTransferButton" disabled={!number} onClick={() => triggerIndirectTransfer(number)} variant="contained">
      {t('indirectTransfer')}
    </TransferButton>
  </ButtonsContainer>
);

const Transfer = ({ actions, indirectTransferCall, t }) => {
  const [transferNumber, setTransferNumber] = useState('');
  const setDisplaySearch = useRef(null);

  const reset = () => {
    setTransferNumber('');
    if (setDisplaySearch.current) {
      setDisplaySearch.current(false);
    }
  };

  const triggerDirectTransfer = number => {
    actions.directTransfer(number);
    reset();
  };

  const triggerIndirectTransfer = number => {
    actions.indirectTransfer(number);
    reset();
  };

  const transferButtonsProps = { setTransferNumber, triggerDirectTransfer, triggerIndirectTransfer, t };

  return (
    <>
      <Keypad
        getDisplaySearchMethod={(method) => setDisplaySearch.current = method}
        number={transferNumber}
        onChange={setTransferNumber}
        placeholder={t('transferInWazo')}
        SearchResultsButtons={(props) => (
          <ResultsWrapper className="transfer-results-button">
            <SearchResultsButtons {...transferButtonsProps} {...props} />
          </ResultsWrapper>
        )}
        transfer
      />

      {indirectTransferCall ? (
        <ButtonsContainer>
          <TransferButton data-testid="cancelIndirectTransfer" onClick={actions.cancelIndirectTransfer} variant="contained">
            {t('cancelIndirectTransfer')}
          </TransferButton>

          <TransferButton
            data-testid="confirmIndirectTransfer"
            disabled={!indirectTransferCall.answered}
            onClick={actions.confirmIndirectTransfer}
            variant="contained"
          >
            {t('confirmIndirectTransfer')}
          </TransferButton>
        </ButtonsContainer>
      ) : (
        <SearchResultsButtons {...transferButtonsProps} contact={{ number: transferNumber }} />
      )}
    </>
  );
};

const mapStateToProps = state => ({
  indirectTransferCall: state.call.indirectTransferCall,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({
    directTransfer,
    indirectTransfer,
    cancelIndirectTransfer,
    confirmIndirectTransfer,
  }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(withTranslation('call')(Transfer)));
