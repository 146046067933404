import i18n from 'i18next';

import { INTEGRATION_CONFIG_RETRIEVED } from '../actions/bridgeActions';
import { DISPLAY_ERROR, ENABLE_AGENT, HIDE_ERROR, UPDATE_LANGUAGE, OVERRIDE_THEMES, OVERRIDE_ASSETS } from '../actions/mainActions';
import { getLocalStorageItem } from '../utils/localstorage';

const initialState = {
  error: null,
  agentEnabled: false,
  integrationConfig: {
    language: getLocalStorageItem('language') || i18n.language,
  },
  overrideThemes: {},
  overrideAssets: {},
};

export const PRESENTIAL_COLORS = {
  GREEN: '#88CD78',
  YELLOW: '#ffbb00',
  RED: '#CC0214',
  GRAY: '#ccc',
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DISPLAY_ERROR:
      return { ...state, error: action.error };

    case OVERRIDE_THEMES:
      return { ...state, overrideThemes: action.themes };

    case OVERRIDE_ASSETS:
      return { ...state, overrideAssets: action.assets };

    case HIDE_ERROR:
      return { ...state, error: null };

    case ENABLE_AGENT:
      return { ...state, agentEnabled: true };

    case INTEGRATION_CONFIG_RETRIEVED:
      return {
        ...state,
        integrationConfig: {
          ...state.integrationConfig,
          ...action.payload.config,
        },
      };

    case UPDATE_LANGUAGE:
      return {
        ...state,
        integrationConfig: {
          ...(state.integrationConfig || {}),
          language: action.payload.language,
        },
      };

    default:
      return state;
  }
}
