/* globals sforce */
import './styles.css';
import {
  BRIDGE_CONFIG_RETRIEVED,
  BRIDGE_AUTHENTICATED,
  BRIDGE_LOGGED_OUT,
  BRIDGE_ON_CLICK_TO_CALL,
  BRIDGE_CALL_ENDED,
  BRIDGE_CALL_INCOMING,
  BRIDGE_CARD_CREATED,
  BRIDGE_CREATE_OR_UPDATE_CARD,
  BRIDGE_CHANGE_LANGUAGE,
  BRIDGE_CHANGE_REQUIRED_SUBSCRIPTION_TYPE,
  BRIDGE_DISPLAY_ERROR,
  BRIDGE_SET_CARD_CONTENT,
  BRIDGE_CALL_OUTGOING,
  BRIDGE_DISPLAY_LINKED_OPTION,
  BRIDGE_SEARCH_OPTIONS,
  BRIDGE_OPTIONS_FETCHED,
  BRIDGE_SAVING_CARD,
  BRIDGE_SAVED_CARD,
  BRIDGE_ENABLE_CLICK_TO_CALL,
  BRIDGE_DISABLE_CLICK_TO_CALL,
  SDK_CLICK_TO_CALL,
  BRIDGE_ON_CALL_ESTABLISHED,
  BRIDGE_OPTIONS_FOUND,
  BRIDGE_UPDATE_FORM_SCHEMA,
  BRIDGE_ENABLE_AGENT,
} from '../../main/sagas/bridgeSagas';
import { sendMessage } from '../../main/utils/integration';
import { NEW_OPTION_VALUE } from '../../card/containers/Autocomplete';
import { setLocalStorageItem } from '../../main/utils/localstorage';

import { parseEntities } from './utils';
import {
  displayPanel,
  createLogCall,
  updateCallLog,
  getCallCenterSettings,
  searchAndPopContact,
  displayRecord,
  refreshView,
  createContact,
  setConfig,
} from './actions';

const REQUIRED_SUBSCRIPTION_TYPE = 3;

const onInit = () => {
  sendMessage(BRIDGE_ENABLE_AGENT);

  if (document.domain === 'localhost') {
    // Fake event to be able to work outside salesforce
    sendMessage(BRIDGE_CHANGE_REQUIRED_SUBSCRIPTION_TYPE, { subscriptionType: REQUIRED_SUBSCRIPTION_TYPE });
    sendMessage(BRIDGE_CONFIG_RETRIEVED, {
      config: {
        server: 'stack.dev.wazo.io',
      },
    });
  }

  // Retrieve settings like server name
  getCallCenterSettings().then(config => {
    setConfig(config);

    setLocalStorageItem('settings', JSON.stringify(config));

    sendMessage(BRIDGE_CHANGE_REQUIRED_SUBSCRIPTION_TYPE, { subscriptionType: REQUIRED_SUBSCRIPTION_TYPE });
    sendMessage(BRIDGE_CONFIG_RETRIEVED, { config });

    if (config.schema) {
      sendMessage(BRIDGE_UPDATE_FORM_SCHEMA, {
        schema: JSON.parse(config.schema),
        uiSchema: JSON.parse(config.uiSchema),
      });
    }

    sendMessage(BRIDGE_CHANGE_LANGUAGE, { language: config.language });
  });

  // Called on click on a number in the salesforce interface
  sforce.opencti.onClickToDial({
    listener: result => {
      displayPanel();

      sendMessage(BRIDGE_ON_CLICK_TO_CALL, { number: result.number });
    },
  });

  sforce.opencti.notifyInitializationComplete();
};

const searchEntities = async (query, fieldId, callType = sforce.opencti.CALL_TYPE.INBOUND) => {
  const rawResults = await searchAndPopContact(query, callType, true);

  delete rawResults.SCREEN_POP_DATA;
  const isContact = fieldId === 'whoId';

  return parseEntities(Object.values(rawResults)).filter(result => isContact ? result.human : !result.human);
};

const searchAllEntities = async (callSession, callType) => {
  try {
    const [contacts, relations] = await Promise.all([
      searchEntities(callSession.number, 'whoId', callType),
      searchEntities(callSession.number, 'whatId', callType),
    ]);
    let entity;

    sendMessage(BRIDGE_OPTIONS_FETCHED, { options: contacts, fieldId: 'whoId' });
    sendMessage(BRIDGE_OPTIONS_FETCHED, { options: relations, fieldId: 'whatId' });

    // Results found
    if (contacts.length) {
      entity = contacts[0];
      sendMessage(BRIDGE_SET_CARD_CONTENT, { field: 'whoId', value: entity });
      if (callType === 'inbound') {
        displayRecord(entity.Id);
      }
    }
  } catch (e) {
    sendMessage(BRIDGE_DISPLAY_ERROR, { error: e.message || e.description });
  }
};

const onCallOutgoing = async callSession => {
  await searchAllEntities(callSession, sforce.opencti.CALL_TYPE.OUTBOUND);
};

const createOrUpdateTask = async (callSession, direction, userExtension, cardContent) => {
  if (!callSession) {
    return;
  }

  sendMessage(BRIDGE_SAVING_CARD);

  let task;

  // Create contact if needed
  if (cardContent.whoId && cardContent.whoId.wazoId === NEW_OPTION_VALUE) {
    cardContent.whoId = await createContact(cardContent.whoId);
    sendMessage(BRIDGE_SET_CARD_CONTENT, { field: 'whoId', value: cardContent.whoId });
  }

  if (!cardContent.cardId) {
    const { whoId, whatId } = cardContent;
    try {
      if (whoId || whatId) {
        task = await createLogCall(callSession, direction, userExtension, cardContent);
      }

      if (task) {
        sendMessage(BRIDGE_CARD_CREATED, { id: task.Id });
      }
    } catch (e) {
      sendMessage(BRIDGE_DISPLAY_ERROR, { error: e.message || e.description });
    }

    if (task) {
      task.cardId = task.Id;
      task.title = cardContent.title;
      task.note = cardContent.note;
      task.whoId = cardContent.whoId;
      task.whatId = cardContent.whatId;
    }
  } else {
    task = cardContent;
  }

  if (task && task.cardId) {
    try {
      await updateCallLog(callSession, direction, userExtension, task);
    } catch (e) {
      sendMessage(BRIDGE_DISPLAY_ERROR, { error: e.message || e.description });
    }
  }

  if (task && task.whatId) {
    displayRecord(task.whatId.Id);
  } else if (task && task.whoId) {
    displayRecord(task.whoId.Id);
  }

  if (task && task.cardId) {
    sendMessage(BRIDGE_SET_CARD_CONTENT, { field: 'cardId', value: task.cardId });
  }

  sendMessage(BRIDGE_SAVED_CARD);

  refreshView();
};

const onMessage = async event => {
  switch (event.data.type) {
    case BRIDGE_AUTHENTICATED:
    case BRIDGE_ENABLE_CLICK_TO_CALL:
      sforce.opencti.enableClickToDial();
      break;
    case BRIDGE_LOGGED_OUT:
    case BRIDGE_DISABLE_CLICK_TO_CALL:
      sforce.opencti.disableClickToDial();
      break;
    case BRIDGE_CALL_ENDED: {
      const { callSession, direction, userExtension, content } = event.data;
      if (!callSession.answered) {
        // Do not create log for not answered calls
        return;
      }

      await createOrUpdateTask(callSession, direction, userExtension, content);
      break;
    }
    case BRIDGE_CALL_INCOMING:
      displayPanel();
      break;
    case BRIDGE_ON_CALL_ESTABLISHED:
      searchAllEntities(event.data.callSession, sforce.opencti.CALL_TYPE.INBOUND);
      break;
    case BRIDGE_CALL_OUTGOING:
      await onCallOutgoing(event.data.callSession);
      break;
    case BRIDGE_CREATE_OR_UPDATE_CARD: {
      const { callSession, direction, userExtension, content } = event.data;

      await createOrUpdateTask(callSession, direction, userExtension, content);
      break;
    }
    case BRIDGE_DISPLAY_LINKED_OPTION:
      await displayRecord(event.data.linkedOptionId);
      break;
    case BRIDGE_SEARCH_OPTIONS: {
      const { query, fieldId } = event.data;
      const options = await searchEntities(query, fieldId);
      sendMessage(BRIDGE_OPTIONS_FOUND, { options, fieldId });
      break;
    }
    case SDK_CLICK_TO_CALL:
      sendMessage(BRIDGE_ON_CLICK_TO_CALL, { number: event.data.extension });
      break;
    default:
      break;
  }
};

window.addEventListener('message', onMessage, false);

const script = document.createElement('script');
script.onload = onInit;
script.src = '/assets/js/opencti.js';

document.head.appendChild(script);
