export const CALL_LOG_CREATED = 'callLogs/CALL_LOG_CREATED';
export const FETCH_CALL_LOGS_START = 'callLogs/FETCH_CALL_LOGS_START';
export const FETCH_CALL_LOGS_SUCCESS = 'callLogs/FETCH_CALL_LOGS_SUCCESS';
export const FETCH_CALL_LOGS_FAILED = 'callLogs/FETCH_CALL_LOGS_FAILED';

export const fetchCallLogs = () => ({ type: FETCH_CALL_LOGS_START });

export const onCallLogCreated = callLog => ({
  type: CALL_LOG_CREATED,
  payload: { callLog },
});

export const onCallLogsFetched = callLogs => ({
  type: FETCH_CALL_LOGS_SUCCESS,
  payload: { callLogs },
});

export const onCallLogsFetchFailed = () => ({
  type: FETCH_CALL_LOGS_FAILED,
});
