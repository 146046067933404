import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { withTranslation } from 'react-i18next';

import Card from '../../card/containers/Card';
import CallDuration from '../components/CallDuration';
import colors from '../../main/themes/colors';
import { INCOMING, OUTGOING } from '../reducers/callReducer';

import RecordButton from './RecordButton';

const Container = styled.div`
  flex: 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
`;

const Summary = styled.div`
  border-radius: 5px;
  border: 1px solid #ccc;
  background: #eee;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 10px 8px;
  margin-bottom: 10px;
`;

const Left = styled.div`
  min-width: 40%;
  max-width: 60%;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  > p:first-child {
    margin-right: 8px;
  }
`;

const Title = styled(Typography)`
  && {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    line-height: 1em;

    &.ringing {
      font-size: 18px;
      font-weight: 500;
      text-align: center;
    }
  }
`;

const Subtitle = styled(Typography)`
  && {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${colors.grey};
    line-height: 1em;
  }
`;

const Ringing = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  h3 {
    margin-bottom: 10px;
  }
`;

const Information = styled(Typography)`
  && {
    color: ${colors.secondary};
  }
`;

const Progress = styled(CircularProgress)`
  && {
    display: block;
    margin: 20px auto;
  }
`;

const StyledError = styled(Typography)`
  && {
    text-align: center;
    margin: 20px 0;
    color: ${colors.error};
  }
`;

const RecordWrapper = styled.div``;

const Call = ({ direction, mainError, error, currentNumber, inCall, callSession, ringing, ended, t }) => (
  <Container data-call-id={callSession.getId()}>
    {!ringing && (
      <Summary>
        <Left>
          <Title className="call-title" variant="subtitle2">
            {callSession.displayName || currentNumber}
          </Title>
          {!!callSession.displayName && callSession.displayName !== currentNumber && <Subtitle variant="caption">{currentNumber}</Subtitle>}
        </Left>

        <Right>
          {inCall && (
            <>
              {callSession && <CallDuration answerTime={callSession.answerTime} />}

              <RecordWrapper>
                <RecordButton />
              </RecordWrapper>
            </>
          )}
        </Right>
        {ended && <Information>{t('callEnded')}</Information>}
      </Summary>
    )}

    {ringing && (
      <Ringing>
        <Title className="ringing" component="h3">{callSession.displayName || currentNumber}</Title>
        <Information align="center">{direction === OUTGOING ? t('calling') : t('incomingCall')}</Information>
        {direction !== INCOMING && <Progress />}
      </Ringing>
    )}

    {error && <StyledError>{error}</StyledError>}
    {mainError && <StyledError>{mainError}</StyledError>}

    <Card />
  </Container>
);

const mapStateToProps = state => ({
  direction: state.call.direction,
  currentNumber: state.call.currentNumber,
  currentContact: state.call.currentContact,
  callSession: state.card.callSession,
  error: state.call.error,
  mainError: state.main.error,
  inCall: state.call.inCall,
  ended: state.call.ended,
  ringing: state.call.ringing,
});

export default connect(mapStateToProps)(React.memo(withTranslation('call')(Call)));
