export default {
  username: 'Identifiant',
  password: 'Mot de passe',
  server: 'Configuration du domaine',

  next: 'Suivant',
  login: 'Connexion',
  logout: 'Déconnexion',
  wrongCredentials: 'Identifiant ou mot de passe incorrect',
  errorOccurred: 'Une erreur est survenue',
  authFailure: 'Erreur d\'authentification',
  noAuthorization: 'Ce compte n\'a pas accès à l\'application',
  ldapConnection: 'Connexion avec LDAP',
  ldapLogin: 'Connexion avec LDAP',
  domainName: 'Nom du domaine LDAP',
  noDomainName: 'Un nom de domaine LDAP doit être fourni pour se connecter',
  wrongDomainName: 'Impossible de trouver un domaine LDAP correspondant, veuillez vérifier son nom',

  userState: 'Statut',
  userStatus: 'Statut',
  available: 'Disponible',
  doNotDisturb: 'Ne pas déranger',
  notAvailable: 'Indisponible',
  addStatus: 'Ajouter un statut...',

  initialLoading: 'Chargement initial...',

  createAccount: 'Si vous n\'etes pas encore un client WAZO, rendez-vous sur <a href="https://wazo.io/contact" rel="noreferrer" target="_blank">https://wazo.io/contact/</a>',
  notConnectedToWDA: 'L\'Application EUC Wazo Desktop ne semble pas active.',
};
