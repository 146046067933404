/* eslint-disable camelcase */
import { put, takeEvery, call, select } from 'redux-saga/effects';
import Wazo from '@wazo/sdk/lib/simple';
import Profile from '@wazo/sdk/lib/domain/Profile';

import { getTranslator } from '../../i18n';
import { INTEGRATION_CONFIG_RETRIEVED } from '../../main/actions/bridgeActions';
import packageJson from '../../../package.json';
import {
  LOGIN_REQUEST,
  AUTHENTICATION_REQUEST,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  SEND_FORWARD_OPTIONS,
  UPDATE_PROFILE,
  SET_APP_CONFIGURATION,
  AUTH_WITH_TOKEN_REQUEST,
  logoutSuccess,
  loginFailed,
  loginSuccess,
  authenticationFailed,
  authenticationSuccess,
  setIsAuthenticating,
} from '../actions/userActions';
import history from '../../main/router/history';
import { load as loadAgent } from '../../agent/actions/agentActions';
import { listenToCallEvents } from '../../call/actions/callActions';
import { fetchWazoSource } from '../../contact/sagas/contactsSagas';
import { fetchCallLogs } from '../../callLogs/actions/callLogActions';
import { fetchVoicemails } from '../../voicemails/actions/voicemailActions';
import { clearLocalStorage, getLocalStorageItem, setLocalStorageItem } from '../../main/utils/localstorage';

const ONE_DAY = 60 * 60 * 24;
const t = getTranslator('user');
export const integration = process.env.REACT_APP_INTEGRATION || 'index';
export const MIN_EXTERNAL_APP_VERSION = '20.15';
export const CLIENT_NAME = 'wazo-softphone';
export const AUTHORIZATION_NAME = 'application-client-access';
export const isLocal = window.location.hostname === 'localhost';
const logger = Wazo.IssueReporter.loggerFor('user-saga');

Wazo.Auth.init(`wazo-softphone-${integration}`, ONE_DAY);

function* startDebug(session, configuration) {
  if (isLocal) {
    Wazo.IssueReporter.disable();
    return;
  }

  const { fluentd_host, fluentd_port, min_log_level } = configuration;

  Wazo.IssueReporter.enable();
  Wazo.IssueReporter.configureRemoteClient({
    host: fluentd_host,
    port: fluentd_port,
    tag: `${CLIENT_NAME}-${packageJson.version}`,
    level: min_log_level || 'log',
    engineVersion: session.engineVersion,
    token: session.token,
    refreshToken: session.refreshToken,
    bufferSize: 10,
    bufferTimeout: 10000,
    maxMessageSize: 500, // enough to display sip INVITE message
    extra: {
      user_uuid: session.uuid,
      engine_uuid: session.engineUuid,
      server: Wazo.Auth.host,
    },
  });
}

function* fetchAppConfiguration(session) {
  if (!session.hasEngineVersionGte(MIN_EXTERNAL_APP_VERSION)) {
    return;
  }

  try {
    const app = yield Wazo.getApiClient().confd.getExternalApp(session.uuid, CLIENT_NAME);
    yield put({ type: SET_APP_CONFIGURATION, configuration: app ? app.configuration : {} });

    if (app?.configuration?.fluentd_host) {
      // @TODO: configure logger
      yield startDebug(session, app.configuration);
    } else {
      Wazo.IssueReporter.configureRemoteClient(null);
    }
  } catch (e) {
    // Nothing to do
  }
}

export function* onLogin(session, success, failure, useLdap) {
  try {
    // Check if the user has access to the application
    yield Wazo.Auth.checkAuthorizations(session, AUTHORIZATION_NAME);

    setLocalStorageItem('userToken', session.token);
    if (session.refreshToken) {
      setLocalStorageItem('refreshToken', session.refreshToken);
    }
    setLocalStorageItem('useLdap', String(useLdap));

    // App configuration
    yield fetchAppConfiguration(session);

    yield put(success(session));
    yield fetchWazoSource();
    yield put(fetchCallLogs());
    yield put(fetchVoicemails());
    yield put(loadAgent());
    yield put(listenToCallEvents());
    // fetchOwnContact is called right after Wazo.Phone.connect()

    logger.info('user environment', {
      server: Wazo.Auth.host,
      url: window.location.href,
      account: session?.profile?.email,
      version: packageJson.version,
      browserUserAgent: window.navigator.userAgent,
      token: session.token,
    });

    yield call(history.push, '/dialer');
  } catch (e) {
    yield put(failure(e instanceof Wazo.InvalidSubscription ? t('noAuthorization') : t('errorOccurred'), e));
    yield logout();
  }
}

function* loginUser({ payload: { username, password, useLdap, domainName: askedDomainName } }) {
  const {
    main: { integrationConfig },
  } = yield select();
  const domainName = askedDomainName || integrationConfig?.domainName;
  const backend = useLdap ? Wazo.Auth.BACKEND_LDAP : Wazo.Auth.BACKEND_WAZO;

  try {
    const session = yield Wazo.Auth.logIn(username, password, backend, { domainName: useLdap ? domainName : null });

    yield onLogin(session, loginSuccess, loginFailed, useLdap);
  } catch (e) {
    yield put(loginFailed(e instanceof Wazo.InvalidSubscription ? t('noAuthorization') : t('wrongCredentials'), e));
  }
}

function* authenticateUserWithToken({ payload: { token, refreshToken } }) {
  try {
    put(setIsAuthenticating());
    const session = yield Wazo.Auth.validateToken(token, refreshToken);

    yield onLogin(session, authenticationSuccess, authenticationFailed);
  } catch (e) {
    yield put(authenticationFailed(t('authFailure'), e));
  }
}

function* authenticateUser() {
  const state = yield select();
  const token = getLocalStorageItem('userToken');
  const refreshToken = getLocalStorageItem('refreshToken');

  if (!token || state.user.authenticated || !state.user.autoLogin) {
    return;
  }

  yield authenticateUserWithToken({ payload: { token, refreshToken } });
}

function* sendForwardOption({ forwardOption }) {
  const {
    user: { session },
  } = yield select();

  logger.info('update forward options', forwardOption);

  yield Wazo.api.confd.updateForwardOption(
    session.uuid,
    forwardOption.key,
    forwardOption.destination,
    forwardOption.enabled,
  );

  const profile = session?.profile;
  const newProfile = profile && profile.setForwardOption && profile.setForwardOption(forwardOption);

  if (newProfile) {
    yield put({ type: UPDATE_PROFILE, profile: Profile.newFrom(newProfile) });
  }
}

export function* onLogout() {
  logger.info('on logout');

  yield put(logoutSuccess());
}

function* logout() {
  logger.info('logout');

  clearLocalStorage();

  Wazo.IssueReporter.disable();

  Wazo.Phone.disconnect();
  yield Wazo.Auth.logout();

  yield call(history.push, '/');
}

export default [
  takeEvery(AUTH_WITH_TOKEN_REQUEST, authenticateUserWithToken),
  takeEvery(LOGIN_REQUEST, loginUser),
  takeEvery(AUTHENTICATION_REQUEST, authenticateUser),
  takeEvery(INTEGRATION_CONFIG_RETRIEVED, authenticateUser),
  takeEvery(LOGOUT_REQUEST, onLogout),
  takeEvery(SEND_FORWARD_OPTIONS, sendForwardOption),
  takeEvery(LOGOUT_SUCCESS, logout),
];
