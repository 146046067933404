import { all } from 'redux-saga/effects';

import bridgeSagas from '../sagas/bridgeSagas';
import mainSagas from '../sagas/mainSagas';
import userSagas from '../../user/sagas/userSagas';
import dialerSagas from '../../dialer/sagas/dialerSagas';
import callSagas from '../../call/sagas/callSagas';
import cardSagas from '../../card/sagas/cardSagas';
import agentSagas from '../../agent/sagas/agentSagas';
import contactSagas from '../../contact/sagas/contactsSagas';
import callLogSagas from '../../callLogs/sagas/callLogSagas';
import voicemailSagas from '../../voicemails/sagas/voicemailSagas';

let sagaCustomizer = sagas => sagas;

export const registerSagaCustomizer = fn => {
  sagaCustomizer = fn;
};

export default function* rootSaga() {
  const sagas = sagaCustomizer([
    ...mainSagas,
    ...bridgeSagas,
    ...dialerSagas,
    ...userSagas,
    ...callSagas,
    ...cardSagas,
    ...agentSagas,
    ...contactSagas,
    ...callLogSagas,
    ...voicemailSagas,
  ]);

  yield all(sagas);
}
