export default {
  primary: '#203890',
  primaryText: '#000000',
  button: '#292C87',
  black: '#000',
  softBlack: 'rgba(0,0,0,0.54)',
  white: '#fff',
  disabled: '#ababab',
  emptyState: '#DCDCDC',
  textDivider: '#C1C0C0',
  callTagBackground: 'rgba(216, 216, 216, 0.2)',
  callTagText: '#747373',
  secondaryBackground: '#203890',
  contrastingText: '#FFF',
  primaryBackground: '#FFF',
  call: '#7DD865',
  border: '#d7d7d7',
  greenButton: '#7ed865',
  greenButtonHover: '#6ebf5a',
  redButton: '#FA5846',
  redButtonHover: '#FF604F',
  red: '#E80539',
  buttonText: '#fff',
  error: '#E80539',
  secondary: '#203890',
  tertiary: '#7F7F7F',
  grey: '#8A95A0',
  secondGrey: '#eee',
  headerColor: '#888',
  avatar: '#bdbdbd', // @FIXME: import from MUI
  divider: 'rgba(0, 0, 0, 0.12)', // @FIXME: import from MUI
  placeholder: 'rgba(22, 44, 66, 0.5)',
  hovered: '#6181F4',
};
