export default {
  hold: 'Hold',
  unhold: 'Unhold',
  mute: 'Mute',
  unmute: 'Unmute',
  calling: 'Calling',
  incomingCall: 'Incoming call',
  callEnded: 'Call ended',
  callError: 'An error occurred during the call',
  transfer: 'Transfer',
  contacts: 'Contacts',
  directTransfer: 'Direct',
  indirectTransfer: 'Indirect',
  cancelIndirectTransfer: 'Cancel',
  confirmIndirectTransfer: 'Confirm',
  startRecording: 'Record',
  stopRecording: 'Stop recording',
  searchInWazo: 'Type here to make a call',
  transferInWazo: 'Type here to transfer the call',
  callLogs: 'Call logs',
  settings: 'Settings',
};
