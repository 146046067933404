import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography/Typography';
import { withTranslation } from 'react-i18next';

import { login, setIsAuthenticating } from '../actions/userActions';
import colors from '../../main/themes/colors';
import logo from '../../main/assets/images/logo.svg';
import { getLocalStorageItem } from '../../main/utils/localstorage';
import ActivityIndicator from '../../integrations/teams/common/ActivityIndicator';

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Content = styled.div`
  padding: 25px 20px;
  form {
    max-width: 400px;
  }
`;

const Logo = styled.img`
  display: block;
  width: 110px;
  margin: 20px auto 50px;
`;

const Form = styled.form``;

export const StyledTextField = styled(TextField)`
  && {
    width: 100%;
    color: ${colors.primary} !important;
    margin-bottom: 30px;
  }
`;

const StyledError = styled(Typography)`
  && {
    text-align: center;
    margin: 20px 0;
    color: ${colors.error};
  }
`;

const LoginButton = styled(Button)`
  && {
    display: block;
    width: ${({ $useLdap }) => $useLdap ? 250 : 150}px;
    margin: 0 auto;
    border-radius: 28px;
    padding-top: ${({ authenticating }) => (authenticating ? '10px' : '6px')};
    height: ${({ authenticating }) => (authenticating ? '40px' : 'auto')};
  }
`;

const Check = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-grow: 1;
  margin: 0 20px 20px;
`;

const Preload = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const Login = ({
  autoLogin,
  assets,
  authenticating,
  error,
  mainError,
  actions,
  t,
  configuredDomainName,
  className,
  extra = {},
}) => {
  const [preloading, setPreloading] = useState(true);
  const [values, setValues] = useState({ username: '', password: '' });
  const [useLdap, setUseLdap] = useState(getLocalStorageItem('useLdap') === 'true');

  const setValue = (name, value) => setValues({ ...values, [name]: value });

  const allFieldsArePresent = () => !!values.username && !!values.password;

  const performLogin = e => {
    e.preventDefault();

    if (extra.actions?.performLogin) {
      extra.actions.performLogin(values, useLdap);
    }

    actions.login(values.username, values.password, useLdap, values.domainName);
  };

  const toggleUseLdap = () => setUseLdap(!useLdap);

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (token && autoLogin) {
      // Display the authenticating loaded as soon as possible
      actions.setIsAuthenticating();
      return;
    }

    setPreloading(false);
  }, []);

  useEffect(() => {
    if (error) {
      setPreloading(false);
    }
  }, [error]);

  if (preloading) {
    return <Preload><ActivityIndicator id="preload-spinner" label={t('initialLoading')} /></Preload>;
  }

  return (
    <Container className={className}>
      <Content>

        <Logo alt="Wazo" id="logo" src={assets?.logo || logo} />

        {!!mainError && <StyledError component="p">{mainError}</StyledError>}

        <Form onSubmit={performLogin}>
          <StyledTextField
            autoComplete="username"
            disabled={authenticating}
            error={!!error}
            id="usernameTextField"
            label={t('username')}
            onChange={event => setValue('username', event.target.value)}
            required
            type="text"
            value={values.email}
            variant="outlined"
          />

          <StyledTextField
            autoComplete="user-password"
            disabled={authenticating}
            error={!!error}
            id="passwordTextField"
            label={t('password')}
            onChange={event => setValue('password', event.target.value)}
            required
            type="password"
            value={values.password}
            variant="outlined"
          />

          {extra.fields}

          {useLdap && !configuredDomainName && (
            <StyledTextField
              disabled={authenticating}
              error={!!error}
              id="domainName"
              label={t('domainName')}
              onChange={event => setValue('domainName', event.target.value)}
              type="text"
              value={values.domainName}
              variant="outlined"
            />
          )}

          <Check onClick={toggleUseLdap}>
            <Switch checked={useLdap} color="primary" onChange={toggleUseLdap} />
            <Typography color="textPrimary">{t('ldapConnection')}</Typography>
          </Check>

          {!!error && <StyledError component="p">{error}</StyledError>}

          <LoginButton
            $useLdap={useLdap}
            authenticating={authenticating ? 1 : 0}
            disabled={authenticating || !allFieldsArePresent()}
            id="loginButton"
            type="submit"
            variant="contained"
          >
            {authenticating ? <CircularProgress size={20} /> : (useLdap ? t('ldapLogin') : t('login'))}
          </LoginButton>

          {extra.footer}
        </Form>
      </Content>
    </Container>
  );
};

const mapStateToProps = state => ({
  authenticating: state.user.authenticating,
  assets: state.main.overrideAssets,
  error: state.user.error,
  autoLogin: state.user.autoLogin,
  mainError: state.main.error,
  configuredDomainName: state.main.integrationConfig?.domainName,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ login, setIsAuthenticating }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(withTranslation('user')(Login)));
