import { eventChannel } from 'redux-saga';
import { put, call, take, takeEvery, select } from 'redux-saga/effects';
import Wazo from '@wazo/sdk/lib/simple';

import bridgeConfig from '../bridge/config';
import {
  LOGIN_SUCCESS,
  AUTHENTICATION_SUCCESS,
  LOGOUT_SUCCESS,
  changeRequiredSubscriptionType,
  toggleAutoLogin,
  authenticateUserWithToken,
} from '../../user/actions/userActions';
import { onConfigRetrieved, onClickToCall, onCardCreated } from '../actions/bridgeActions';
import {
  CALL_ENDED,
  CALL_HOLD,
  CALL_LOCALLY_ACCEPTED,
  CALL_MADE,
  CALL_MUTE,
  CALL_REMOTLY_ACCEPTED,
  CALL_RESUME,
  CALL_SEND_DTMF,
  CALL_UNMUTE,
  CANCEL_INDIRECT_TRANSFER,
  CONFIRM_INDIRECT_TRANSFER,
  CREATE_INDIRECT_TRANSFER,
  DIRECT_TRANSFER,
  INDIRECT_TRANSFER_CALL_MADE,
  ON_INDIRECT_TRANSFER_DONE,
  ON_INVITE,
  REJECT_CALL,
  START_RECORDING,
  STOP_RECORDING,
  disableRegister,
} from '../../call/actions/callActions';
import {
  CREATE_OR_UPDATE_CARD_REQUEST,
  DISPLAY_LINKED_OPTION,
  SEARCH_OPTIONS,
  cardCreatedOrUpdated,
  onOptionsFetched,
  onOptionsFound,
  setValue as setCardValue,
  onSavingCard,
  onSavedCard,
  SKIP_CARD,
  SAVE_CARD_REQUEST,
  updateFormSchema,
} from '../../card/actions/cardActions';
import i18n from '../../i18n';
import {
  displayError,
  enableAgent,
  overrideAssets,
  overrideThemes,
  UPDATE_LANGUAGE,
  updateLanguage,
} from '../actions/mainActions';
import { sendMessage, sendParentMessage } from '../utils/integration';
import { CONTACT_SEARCH_START } from '../../contact/actions/contactActions';
import {
  AGENT_LOGIN_SUCCESS,
  AGENT_LOGOUT_SUCCESS,
  AGENT_PAUSE_SUCCESS,
  AGENT_RESUME_SUCCESS,
} from '../../agent/actions/agentActions';
import { injectCss } from '../utils/css';
import { enableTranslationDebug, overrideTranslations } from '../utils/whitelabel';
import { setLocalStorageItem } from '../utils/localstorage';
import { CALL_LOG_CREATED } from '../../callLogs/actions/callLogActions';
import { bindEvents } from '../utils/sagas';

const logger = Wazo.IssueReporter.loggerFor('bridge-saga');

// /!\ Beware to not use these constants in sagas. They are used to communicate with the integration (salesforce, ...)
export const BRIDGE_CONFIG_RETRIEVED = 'bridge/CONFIG_RETRIEVED';
export const BRIDGE_AUTHENTICATED = 'bridge/AUTHENTICATED';
export const BRIDGE_LOGGED_OUT = 'bridge/LOGGED_OUT';
export const BRIDGE_CREATE_OR_UPDATE_CARD = 'bridge/BRIDGE_CREATE_OR_UPDATE_CARD';
export const BRIDGE_ON_CLICK_TO_CALL = 'bridge/ON_CLICK_TO_CALL';
export const BRIDGE_ON_CALL_LOCALLY_ACCEPTED = 'bridge/BRIDGE_ON_CALL_LOCALLY_ACCEPTED';
export const BRIDGE_ON_CALL_REJECTED = 'bridge/BRIDGE_ON_CALL_REJECTED';
export const BRIDGE_ON_CALL_ESTABLISHED = 'bridge/BRIDGE_ON_CALL_ESTABLISHED';
export const BRIDGE_CALL_ENDED = 'bridge/CALL_ENDED';
export const BRIDGE_CALL_INCOMING = 'bridge/CALL_INCOMING';
export const BRIDGE_CALL_OUTGOING = 'bridge/CALL_OUTGOING';
export const BRIDGE_CARD_CREATED = 'bridge/LOG_CREATED';
export const BRIDGE_OPTIONS_FOUND = 'bridge/BRIDGE_OPTIONS_FOUND';
export const BRIDGE_SET_CARD_CONTENT = 'bridge/BRIDGE_SET_CARD_CONTENT';
export const BRIDGE_CHANGE_LANGUAGE = 'bridge/CHANGE_LANGUAGE';
export const BRIDGE_CHANGE_REQUIRED_SUBSCRIPTION_TYPE = 'bridge/CHANGE_REQUIRED_SUBSCRIPTION_TYPE';
export const BRIDGE_DISPLAY_LINKED_OPTION = 'bridge/DISPLAY_LINKED_OPTION';
export const BRIDGE_DISPLAY_ERROR = 'bridge/BRIDGE_DISPLAY_ERROR';
export const BRIDGE_SEARCH_OPTIONS = 'bridge/BRIDGE_SEARCH_OPTIONS';
export const BRIDGE_OPTIONS_FETCHED = 'bridge/BRIDGE_OPTIONS_FETCHED';
export const BRIDGE_SAVING_CARD = 'bridge/BRIDGE_SAVING_CARD';
export const BRIDGE_SAVED_CARD = 'bridge/BRIDGE_SAVED_CARD';
export const BRIDGE_WAZO_CONTACT_SEARCH = 'bridge/BRIDGE_WAZO_CONTACT_SEARCH';
export const BRIDGE_ON_AGENT_LOGGED_IN = 'bridge/BRIDGE_ON_AGENT_LOGGED_IN';
export const BRIDGE_ON_AGENT_LOGGED_OUT = 'bridge/BRIDGE_ON_AGENT_LOGGED_OUT';
export const BRIDGE_ON_AGENT_PAUSED = 'bridge/BRIDGE_ON_AGENT_PAUSED';
export const BRIDGE_ON_AGENT_RESUMED = 'bridge/BRIDGE_ON_AGENT_RESUMED';
export const BRIDGE_ON_LANGUAGE_CHANGED = 'bridge/BRIDGE_ON_LANGUAGE_CHANGED';
export const BRIDGE_ON_CALL_HELD = 'bridge/BRIDGE_ON_CALL_HELD';
export const BRIDGE_ON_CALL_RESUMED = 'bridge/BRIDGE_ON_CALL_RESUMED';
export const BRIDGE_ON_CALL_MUTED = 'bridge/BRIDGE_ON_CALL_MUTED';
export const BRIDGE_ON_CALL_UN_MUTED = 'bridge/BRIDGE_ON_CALL_UN_MUTED';
export const BRIDGE_ON_DTMF = 'bridge/BRIDGE_ON_DTMF';
export const BRIDGE_ON_DIRECT_TRANSFER = 'bridge/BRIDGE_ON_DIRECT_TRANSFER';
export const BRIDGE_ON_CREATE_INDIRECT_TRANSFER = 'bridge/BRIDGE_ON_CREATE_INDIRECT_TRANSFER';
export const BRIDGE_ON_CANCEL_INDIRECT_TRANSFER = 'bridge/BRIDGE_ON_CANCEL_INDIRECT_TRANSFER';
export const BRIDGE_ON_CONFIRM_INDIRECT_TRANSFER = 'bridge/BRIDGE_ON_CONFIRM_INDIRECT_TRANSFER';
export const BRIDGE_ON_INDIRECT_TRANSFER_CALL_MADE = 'bridge/BRIDGE_ON_INDIRECT_TRANSFER_CALL_MADE';
export const BRIDGE_ON_INDIRECT_TRANSFER_DONE = 'bridge/BRIDGE_ON_INDIRECT_TRANSFER_DONE';
export const BRIDGE_ON_START_RECORDING = 'bridge/BRIDGE_ON_START_RECORDING';
export const BRIDGE_ON_STOP_RECORDING = 'bridge/BRIDGE_ON_STOP_RECORDING';
export const BRIDGE_ENABLE_CLICK_TO_CALL = 'bridge/BRIDGE_ENABLE_CLICK_TO_CALL';
export const BRIDGE_DISABLE_CLICK_TO_CALL = 'bridge/BRIDGE_DISABLE_CLICK_TO_CALL';
export const BRIDGE_DISABLE_REGISTER = 'bridge/BRIDGE_DISABLE_REGISTER';
export const BRIDGE_UPDATE_FORM_SCHEMA = 'bridge/BRIDGE_UPDATE_FORM_SCHEMA';
export const BRIDGE_ON_CARD_CANCELED = 'bridge/BRIDGE_ON_CARD_CANCELED';
export const BRIDGE_ENABLE_AGENT = 'bridge/BRIDGE_ENABLE_AGENT';
export const BRIDGE_INJECT_CSS = 'bridge/BRIDGE_INJECT_CSS';
export const BRIDGE_CUSTOMIZE_APPEARANCE = 'bridge/BRIDGE_CUSTOMIZE_APPEARANCE';
export const BRIDGE_ENABLE_DEBUG = 'bridge/BRIDGE_ENABLE_DEBUG';
export const BRIDGE_CALL_LOG_CREATED = 'bridge/BRIDGE_CALL_LOG_CREATED';
export const BRIDGE_ON_WEBSOCKET_MESSAGE = 'bridge/BRIDGE_ON_WEBSOCKET_MESSAGE';
export const BRIDGE_LOGIN_WITH_TOKEN = 'bridge/BRIDGE_LOGIN_WITH_TOKEN';

export const SDK_CLICK_TO_CALL = 'sdk/CLICK_TO_CALL';
export const SDK_ON_OUTGOING_CALL_MADE = 'sdk/SDK_ON_OUTGOING_CALL_MADE';
export const SDK_CALL_ENDED = 'sdk/ON_CALL_ENDED';
export const SDK_CALL_INCOMING = 'sdk/SDK_CALL_INCOMING';
export const SDK_AUTHENTICATED = 'sdk/SDK_AUTHENTICATED';

const events = {
  [BRIDGE_CONFIG_RETRIEVED]: function*(event) {
    const { data: { config } } = event;
    if (!config) {
      return;
    }
    const { server, port } = config;

    logger.info('on config retrieved', config);

    const stackHostname = `${server}${port && +port !== 443 ? `:${port}` : ''}`;
    Wazo.Auth.setHost(stackHostname);

    bridgeConfig.setValue('server', stackHostname);
    yield put(toggleAutoLogin(config.disableAutoLogin));

    yield put(onConfigRetrieved(config));
    if (config.language) {
      yield put(updateLanguage(config.language));
      setLocalStorageItem('language', config.language);
      i18n.changeLanguage(config.language);
    }
  },
  [BRIDGE_LOGIN_WITH_TOKEN]: function*(event) {
    yield put(authenticateUserWithToken(event.data.token, event.data.refreshToken));
  },
  [BRIDGE_ON_CLICK_TO_CALL]: function*(event) {
    yield put(onClickToCall(event.data.number));
  },
  [BRIDGE_CARD_CREATED]: function*(event) {
    yield put(onCardCreated(event.data.id));
  },
  [BRIDGE_OPTIONS_FETCHED]: function*(event) {
    const { options, fieldId } = event.data;
    yield put(onOptionsFetched(options, fieldId));
  },
  [BRIDGE_OPTIONS_FOUND]: function*(event) {
    const { options, fieldId } = event.data;
    yield put(onOptionsFound(options, fieldId));
  },
  [BRIDGE_SAVING_CARD]: function*() {
    yield put(onSavingCard());
  },
  [BRIDGE_SAVED_CARD]: function*() {
    yield put(onSavedCard());
  },
  [BRIDGE_SET_CARD_CONTENT]: function*(event) {
    yield put(setCardValue(event.data.field, event.data.value));
  },
  [BRIDGE_DISPLAY_ERROR]: function*(event) {
    yield put(displayError(event.data.error));
  },
  [BRIDGE_CHANGE_LANGUAGE]: event => {
    i18n.changeLanguage(event.data.language.toLowerCase());
  },
  [BRIDGE_DISABLE_REGISTER]: function*() {
    yield put(disableRegister());
  },
  [BRIDGE_CHANGE_REQUIRED_SUBSCRIPTION_TYPE]: function*(event) {
    const { subscriptionType } = event.data;
    Wazo.Auth.minSubscriptionType = subscriptionType - 1;

    yield put(changeRequiredSubscriptionType(subscriptionType));
  },
  [BRIDGE_UPDATE_FORM_SCHEMA]: function*(event) {
    const { schema, uiSchema } = event.data;

    yield put(updateFormSchema(schema, uiSchema));
  },
  [BRIDGE_ENABLE_AGENT]: function*() {
    yield put(enableAgent());
  },
  [BRIDGE_INJECT_CSS]: function*(event) {
    injectCss(event.data.css);
  },
  [BRIDGE_ENABLE_DEBUG]: function*() {
    enableTranslationDebug();
  },
  [BRIDGE_CUSTOMIZE_APPEARANCE]: function*(event) {
    const { themes, translations, assets } = event.data;
    if (translations) {
      overrideTranslations(translations);
    }
    if (assets) {
      yield put(overrideAssets(assets));
    }
    if (themes) {
      yield put(overrideThemes(themes));
    }
  },
};

function* bindWebsocketEvents() {
  // Listen to websocket message to re-send them
  const wsEvents = Wazo.Websocket.eventLists.reduce((acc, val) => {
    acc[val] = function* (message) {
      sendParentMessage(BRIDGE_ON_WEBSOCKET_MESSAGE, { message });
    };

    return acc;
  }, {});

  yield bindEvents(wsEvents, Wazo.Websocket);
}

function* bindIntegrationEvents() {
  const createChannel = () =>
    eventChannel(emit => {
      window.addEventListener('message', emit);

      return () => {};
    });

  const channel = yield call(createChannel);

  while (true) {
    const payload = yield take(channel);

    if (payload?.data?.type in events) {
      logger.info('on bridge event', { type: payload.data.type, payload });

      yield events[payload.data.type](payload);
    }
  }
}

// Have to clone callSession to avoid issue: `this._onAccepted(e) could not be cloned`
const cloneCallSession = callSession => ({
  answered: callSession.answered,
  sipCallId: callSession.sipCallId,
  callId: callSession.callId,
  sipStatus: callSession.sipStatus,
  number: callSession.number,
  displayName: callSession.displayName,
  callerNumber: callSession.callerNumber,
  creationTime: callSession.creationTime,
  answerTime: callSession.answerTime,
  endTime: callSession.endTime,
});

function* createOrUpdateCard({ payload: { cardContent, callSession } }) {
  const {
    call: { direction },
    user: { session },
  } = yield select();
  const userExtension = session.primaryLine().extensions[0].exten;

  sendMessage(BRIDGE_CREATE_OR_UPDATE_CARD, {
    callSession: cloneCallSession(callSession),
    userExtension,
    direction,
    content: cardContent,
  });
  yield put(cardCreatedOrUpdated());
}

function* onCallEnded() {
  const {
    call: { direction, currentCallSession },
    user: { session },
    card,
  } = yield select();

  const userExtension = session.primaryLine().extensions[0].exten;

  logger.info('on call ended');

  // Have to clone callSession to avoid issue: `this._onAccepted(session) could not be cloned`
  const callSession = cloneCallSession(currentCallSession);

  // Enable click to call for non answered calls
  if (!callSession.answered) {
    sendMessage(BRIDGE_ENABLE_CLICK_TO_CALL);
  }

  const { content } = card;

  sendMessage(BRIDGE_CALL_ENDED, { direction, callSession, userExtension, content });
  sendParentMessage(SDK_CALL_ENDED, { direction, callSession, userExtension, content });
}

const onAuthentication = ({ payload: { session } }) => {
  sendMessage(BRIDGE_AUTHENTICATED, { session });
  sendParentMessage(SDK_AUTHENTICATED, { session });
};

const onLogout = () => {
  sendMessage(BRIDGE_LOGGED_OUT);
};

const onInvite = ({ payload: { callSession } }) => {
  sendMessage(BRIDGE_CALL_INCOMING, { callSession: cloneCallSession(callSession) });

  sendMessage(BRIDGE_DISABLE_CLICK_TO_CALL);

  sendParentMessage(SDK_CALL_INCOMING, { callSession: cloneCallSession(callSession) });
};

function* callRemotelyAccepted() {
  const {
    call: { currentCallSession },
  } = yield select();

  sendMessage(BRIDGE_ON_CALL_ESTABLISHED, { callSession: cloneCallSession(currentCallSession) });
  sendParentMessage(BRIDGE_ON_CALL_ESTABLISHED, { callSession: cloneCallSession(currentCallSession) });
}

const onCallMade = ({ payload: { callSession } }) => {
  sendMessage(BRIDGE_CALL_OUTGOING, { callSession: cloneCallSession(callSession) });

  sendMessage(BRIDGE_DISABLE_CLICK_TO_CALL);

  sendParentMessage(SDK_ON_OUTGOING_CALL_MADE, { callSession: cloneCallSession(callSession) });
};

function* onCallAnswered() {
  const {
    call: { currentCallSession },
  } = yield select();
  sendParentMessage(BRIDGE_ON_CALL_LOCALLY_ACCEPTED, { call: cloneCallSession(currentCallSession) });
}

function* onCallRejected() {
  const {
    call: { currentCallSession },
  } = yield select();

  sendMessage(BRIDGE_ON_CALL_REJECTED, { call: cloneCallSession(currentCallSession) });
}

const onWazoContactSearch = ({ payload: { query } }) => sendMessage(BRIDGE_WAZO_CONTACT_SEARCH, { query });

const onAgentLoggedIn = () => sendMessage(BRIDGE_ON_AGENT_LOGGED_IN);
const onAgentLoggedOut = () => sendMessage(BRIDGE_ON_AGENT_LOGGED_OUT);
const onAgentPaused = () => sendMessage(BRIDGE_ON_AGENT_PAUSED);
const onAgentResumed = () => sendMessage(BRIDGE_ON_AGENT_RESUMED);
const onLanguageChanged = ({ payload: { language } }) => sendMessage(BRIDGE_ON_LANGUAGE_CHANGED, { language });

const onCallHeld = () => sendMessage(BRIDGE_ON_CALL_HELD);
const onCallResumed = () => sendMessage(BRIDGE_ON_CALL_RESUMED);
const onCallMuted = () => sendMessage(BRIDGE_ON_CALL_MUTED);
const onCallUnmuted = () => sendMessage(BRIDGE_ON_CALL_UN_MUTED);
const onDTMFSent = ({ payload: { value } }) => sendMessage(BRIDGE_ON_DTMF, { tone: value });
const onDirectTransfer = ({ payload: { number } }) => sendMessage(BRIDGE_ON_DIRECT_TRANSFER, { number });
const onCreateIndirectTransfer = ({ payload: { number } }) =>
  sendMessage(BRIDGE_ON_CREATE_INDIRECT_TRANSFER, { number });
const onCancelIndirectTransfer = () => sendMessage(BRIDGE_ON_CANCEL_INDIRECT_TRANSFER);
const onConfirmIndirectTransfer = () => sendMessage(BRIDGE_ON_CONFIRM_INDIRECT_TRANSFER);
const onIndirectCallMade = ({ payload: { indirectCall } }) =>
  sendMessage(BRIDGE_ON_INDIRECT_TRANSFER_CALL_MADE, { call: cloneCallSession(indirectCall) });
const onIndirectTransferDone = () => sendMessage(BRIDGE_ON_INDIRECT_TRANSFER_DONE);
const onStartRecording = () => sendMessage(BRIDGE_ON_START_RECORDING);
const onStopRecording = () => sendMessage(BRIDGE_ON_STOP_RECORDING);

const onSkipCard = () => sendMessage(BRIDGE_ENABLE_CLICK_TO_CALL);
const enableClickToCallAfterSavedCard = () => sendMessage(BRIDGE_ENABLE_CLICK_TO_CALL);

const displayLinkedOption = ({ payload: { linkedOptionId } }) =>
  sendMessage(BRIDGE_DISPLAY_LINKED_OPTION, { linkedOptionId });

const onSearchOptions = ({ payload: { query, fieldId } }) => sendMessage(BRIDGE_SEARCH_OPTIONS, { query, fieldId });

const onCardCanceled = () => sendMessage(BRIDGE_ON_CARD_CANCELED);

const onCallLogCreated = ({ payload: { callLog } }) => sendMessage(BRIDGE_CALL_LOG_CREATED, { callLog });

export default [
  bindIntegrationEvents(),
  bindWebsocketEvents(),
  takeEvery(LOGIN_SUCCESS, onAuthentication),
  takeEvery(AUTHENTICATION_SUCCESS, onAuthentication),
  takeEvery(LOGOUT_SUCCESS, onLogout),
  takeEvery(CREATE_OR_UPDATE_CARD_REQUEST, createOrUpdateCard),
  takeEvery(CALL_LOCALLY_ACCEPTED, onCallAnswered),
  takeEvery(REJECT_CALL, onCallRejected),
  takeEvery(CALL_REMOTLY_ACCEPTED, callRemotelyAccepted),
  takeEvery(CALL_ENDED, onCallEnded),
  takeEvery(ON_INVITE, onInvite),
  takeEvery(CALL_REMOTLY_ACCEPTED, callRemotelyAccepted),
  takeEvery(CALL_MADE, onCallMade),
  takeEvery(CONTACT_SEARCH_START, onWazoContactSearch),
  takeEvery(AGENT_LOGIN_SUCCESS, onAgentLoggedIn),
  takeEvery(AGENT_LOGOUT_SUCCESS, onAgentLoggedOut),
  takeEvery(AGENT_PAUSE_SUCCESS, onAgentPaused),
  takeEvery(AGENT_RESUME_SUCCESS, onAgentResumed),
  takeEvery(UPDATE_LANGUAGE, onLanguageChanged),
  takeEvery(CALL_HOLD, onCallHeld),
  takeEvery(CALL_RESUME, onCallResumed),
  takeEvery(CALL_MUTE, onCallMuted),
  takeEvery(CALL_UNMUTE, onCallUnmuted),
  takeEvery(CALL_SEND_DTMF, onDTMFSent),
  takeEvery(DIRECT_TRANSFER, onDirectTransfer),
  takeEvery(CREATE_INDIRECT_TRANSFER, onCreateIndirectTransfer),
  takeEvery(CANCEL_INDIRECT_TRANSFER, onCancelIndirectTransfer),
  takeEvery(CONFIRM_INDIRECT_TRANSFER, onConfirmIndirectTransfer),
  takeEvery(INDIRECT_TRANSFER_CALL_MADE, onIndirectCallMade),
  takeEvery(ON_INDIRECT_TRANSFER_DONE, onIndirectTransferDone),
  takeEvery(START_RECORDING, onStartRecording),
  takeEvery(STOP_RECORDING, onStopRecording),
  takeEvery(SKIP_CARD, onSkipCard),
  takeEvery(SAVE_CARD_REQUEST, enableClickToCallAfterSavedCard),
  takeEvery(DISPLAY_LINKED_OPTION, displayLinkedOption),
  takeEvery(SEARCH_OPTIONS, onSearchOptions),
  takeEvery(SKIP_CARD, onCardCanceled),
  takeEvery(CALL_LOG_CREATED, onCallLogCreated),
];
