import Wazo from '@wazo/sdk/lib/simple';
import Voicemail from '@wazo/sdk/lib/domain/Voicemail';

export const ACKNOWLEDGE_VOICEMAIL = 'voicemail/ACKNOWLEDGE_VOICEMAIL';

export const ON_VOICEMAIL_CREATED = 'voicemails/ON_VOICEMAIL_CREATED';
export const ON_VOICEMAIL_DELETED = 'voicemails/ON_VOICEMAIL_DELETED';

export const FETCH_VOICEMAILS_START = 'voicemails/FETCH_VOICEMAILS_START';
export const FETCH_VOICEMAILS_SUCCESS = 'voicemails/FETCH_VOICEMAILS_SUCCESS';
export const FETCH_VOICEMAILS_FAILED = 'voicemails/FETCH_VOICEMAILS_SUCCESS';

export const DELETE_VOICEMAIL_REQUEST = 'voicemail/DELETE_VOICEMAIL_REQUEST';
export const DELETE_VOICEMAIL_SUCCESS = 'voicemail/DELETE_VOICEMAIL_SUCCESS';
export const DELETE_VOICEMAIL_FAILURE = 'voicemail/DELETE_VOICEMAIL_FAILURE';

export const fetchVoicemails = () => ({ type: FETCH_VOICEMAILS_START });

export const onVoicemailCreated = voicemail => ({
  type: ON_VOICEMAIL_CREATED,
  payload: { voicemail },
});

export const onVoicemailDeleted = voicemail => ({
  type: ON_VOICEMAIL_DELETED,
  payload: { voicemail },
});

export const onVoicemailsFetched = voicemails => ({
  type: FETCH_VOICEMAILS_SUCCESS,
  payload: { voicemails },
});

export const onVoicemailsFetchFailed = () => ({
  type: FETCH_VOICEMAILS_FAILED,
});

export const deleteVoicemail = voicemail => ({ type: DELETE_VOICEMAIL_REQUEST, payload: { voicemail } });

export const getVoicemailUrl = async voicemail =>
  await Wazo.api.calld.getVoicemailUrl(voicemail);

export const voicemailFileIsAvailable = async url => {
  let result = true;
  try {
    const response = await fetch(url, {
      method: 'HEAD',
      cache: 'no-cache',
    });

    if (response.status > 400) {
      result = false;
    }
  } catch (error) {
    result = false;
  }

  return result;
};

const downloadDataFile = async (file, filename) => {
  const fileBlob = await file.blob();

  const a = document.createElement('a');
  a.href = URL.createObjectURL(fileBlob);
  a.target = '_blank';
  a.setAttribute('download', filename);

  a.click();
};

export const downloadVoicemail = (voicemail, firstCall = true) => async dispatch => {
  const url = await getVoicemailUrl(voicemail);
  const file = await fetch(url);

  // Generate a new token if the current one is too old
  if (firstCall && file.status === 401) {
    await Wazo.api.refreshTokenCallback();
    // Replay the call after refreshing the token
    await dispatch(downloadVoicemail(voicemail, false));
    return;
  }

  await downloadDataFile(file, `${voicemail.caller.name || voicemail.caller.number}-${voicemail.date.toString()}`);
};

export const acknowledgeVoicemail = voicemail => {
  const newVoicemail = Voicemail.newFrom(voicemail.acknowledge());
  newVoicemail.recent = voicemail.recent;

  return {
    type: ACKNOWLEDGE_VOICEMAIL,
    voicemail: newVoicemail,
  };
};
