const PHONE_NUMBER_REGEX = new RegExp('[-.*# ()+]', 'g');
const REMOVE_DIGIT_REGEX = new RegExp('[0-9]', 'g');

export default class PhoneNumber {

  static isValid(raw: Number | String) {
    if (!raw) {
      return false;
    }

    if (typeof raw === 'number') {
      return true;
    }

    const phoneNumber = raw.replace(PHONE_NUMBER_REGEX, '');
    const noNumber = phoneNumber.replace(REMOVE_DIGIT_REGEX, '');
    return !noNumber.trim().length;
  }

}
