import {
  FETCH_VOICEMAILS_START,
  FETCH_VOICEMAILS_SUCCESS,
  ACKNOWLEDGE_VOICEMAIL,
  ON_VOICEMAIL_CREATED, ON_VOICEMAIL_DELETED,
} from '../actions/voicemailActions';

const initialState = {
  fetching: false,
  voicemails: [],
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case FETCH_VOICEMAILS_START:
      return { ...state, fetching: true };

    case FETCH_VOICEMAILS_SUCCESS:
      return { ...state, voicemails: action.payload?.voicemails, fetching: false };

    case ACKNOWLEDGE_VOICEMAIL: {
      const updatedVoicemails = [...state.voicemails];
      const indexOf = updatedVoicemails.findIndex(voicemail => voicemail.is(action.voicemail));
      updatedVoicemails.splice(indexOf, 1, action.voicemail);

      return {
        ...state,
        voicemails: updatedVoicemails,
      };
    }

    case ON_VOICEMAIL_CREATED:
      return { ...state, voicemails: [...state.voicemails, action.payload.voicemail] };

    case ON_VOICEMAIL_DELETED:
      return { ...state, voicemails: state.voicemails.filter(voicemail => !action.payload.voicemail.is(voicemail)) };

    default:
      return state;
  }
}
