import React from 'react';
import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import colors from '../themes/colors';

const StyledLink = styled(Link)`
  position: relative;
  padding: 10px;
  flex: 1;
  margin: 0 auto;

  & button {
    margin: 0 auto;
    display: block;
  }

  svg {
    color: ${({ active, disabled }) => (disabled ? colors.grey : !!active ? colors.primary : 'inherit')};
  }
`;

const ButtonWithIcon = ({ active, to, onClick, disabled, children, ...props }) => (
  <StyledLink active={active ? 1 : 0} disabled={disabled} onClick={onClick} to={to || ''} {...props}>
    <IconButton>{children}</IconButton>
  </StyledLink>
);

export default ButtonWithIcon;
