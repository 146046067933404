import React, { useReducer } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import Typography from '@mui/material/Typography/Typography';
import FormControl from '@mui/material/FormControl';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import ForwardOption from '@wazo/sdk/lib/domain/ForwardOption';

import { getUnconditionalCallForward, getNoAnswerCallForward, getBusyCallForward } from '../selectors/userSelector';
import { sendForwardOption } from '../actions/userActions';
import metrics from '../../main/themes/metrics';
import { isExtensionValid } from '../../main/utils/phone';

const Container = styled.div`
  padding-top: 30px;
`;

const Title = styled(Typography)`
  && {
    margin-bottom: 30px;
    text-align: center;
    font-size: 30px;
  }
`;

const StyledForm = styled(FormControl)`
  && {
    display: block;
    width: 80%;
    margin: 0 auto !important;
    & > div {
      width: 100%;
    }
  }
`;

const ForwardingOption = styled.div`
  display: flex;
  align-items: flex-end;
  padding-bottom: ${metrics.spacing.larger}px;

  > span:first-child {
    margin-right: ${metrics.spacing.small}px;
  }
`;

const StyledTextField = styled(TextField)`
  && {
    width: 100%;

    & #searchField-helper-text {
      transition: color .2s linear;
      color: #999;
    }
  }
`;

const SaveButton = styled(Button)`
  && {
    margin: ${metrics.spacing.large}px auto;
    display: block;
  }
`;

const isForwardDestinationValid = forwardOption => {
  if (!forwardOption || !forwardOption.destination || forwardOption.destination.length === 0) {
    return true;
  }
  return isExtensionValid(forwardOption.destination);
};

const isForwardOptionModified = (current, initial) => {
  if (!current) {
    return false;
  }
  return current.destination !== initial.destination || current.enabled !== initial.enabled;
};

const CallForwarding = ({ t, actions, unconditionalCallForward, noAnswerCallForward, busyCallForward }) => {
  const [state, setState] = useReducer(
    (data, partialData) => ({ ...data, ...partialData }),
    { unconditional: unconditionalCallForward, noAnswer: noAnswerCallForward, busy: busyCallForward },
  );

  const update = () => {
    const { unconditional, noAnswer, busy } = state;

    if (!!unconditional) {
      actions.sendForwardOption(new ForwardOption(unconditional).setEnabled(unconditional.enabled));
    }

    if (!!noAnswer) {
      actions.sendForwardOption(new ForwardOption(noAnswer).setEnabled(noAnswer.enabled));
    }

    if (!!busy) {
      actions.sendForwardOption(new ForwardOption(busy).setEnabled(busy.enabled));
    }
  };

  const onCheckBoxChange = (optionKey, value) => {
    if (state[optionKey] && (state[optionKey].destination.length !== 0 || value === false)) {
      setState({
        [optionKey]: {
          ...state[optionKey],
          enabled: value,
        },
      });
    }
  };

  const onDestinationChange = async (optionKey, value) => {
    setState({
      [optionKey]: {
        ...state[optionKey],
        destination: value,
        enabled: value ? state[optionKey].enabled : false,
      },
    });
  };

  const areAllNumbersValid = () =>
    isForwardDestinationValid(state.unconditional) &&
    isForwardDestinationValid(state.noAnswer) &&
    isForwardDestinationValid(state.busy);

  const hasChanged = () => (
    isForwardOptionModified(state.unconditional, unconditionalCallForward) ||
    isForwardOptionModified(state.noAnswer, noAnswerCallForward) ||
    isForwardOptionModified(state.busy, busyCallForward)
  );

  const renderOption = name => {
    const value = state[name];

    return (
      <ForwardingOption>
        <Switch
          checked={value ? value.enabled : false}
          color="primary"
          id={`${name}-switch`}
          onChange={event => onCheckBoxChange(name, event.target.checked)}
          value={value ? value.enabled : false}
        />
        <StyledTextField
          error={!isForwardDestinationValid(value)}
          id={`${name}-input`}
          label={t(name)}
          onChange={event => {
            onDestinationChange(name, event.target.value);
          }}
          value={value ? value.destination : ''}
        />
      </ForwardingOption>
    );
  };

  return (
    <Container className="call-forwarding-container">
      <Title className="call-forwarding-title" component="h1">
        {t('callForwarding')}
      </Title>

      <StyledForm>
        {renderOption('unconditional', 0)}
        {renderOption('noAnswer', 1)}
        {renderOption('busy', 2)}

        <SaveButton
          color="primary"
          disabled={!hasChanged() || !areAllNumbersValid()}
          id="saveButton"
          onClick={update}
          variant="contained"
        >
          {t('save')}
        </SaveButton>
      </StyledForm>
    </Container>
  );
};

const mapStateToProps = state => ({
  unconditionalCallForward: getUnconditionalCallForward(state),
  noAnswerCallForward: getNoAnswerCallForward(state),
  busyCallForward: getBusyCallForward(state),
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ sendForwardOption }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation('settings')(CallForwarding));
