import Wazo from '@wazo/sdk/lib/simple';

const logger = Wazo.IssueReporter.loggerFor('bridge-saga');

export const sendParentMessage = (type, payload) => {
  if (window !== window.parent) {
    window.parent.postMessage({ type, ...payload }, '*');
  }
};

export const sendMessage = (type, payload) => {
  const safePayload = { ...payload };
  delete safePayload.session;
  logger.info('sending bridge message', { type, payload: safePayload });

  window.postMessage({ type, ...payload }, '*');
  sendParentMessage(type, payload);
};
