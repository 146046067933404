import { createTheme } from '@mui/material/styles';
import { merge } from 'lodash';

import defaultColors from './colors';
import defaultMetrics from './metrics';

let integrationTheme = {};
try {
  if (process.env.REACT_APP_INTEGRATION) {
    integrationTheme = require(`../../integrations/${process.env.REACT_APP_INTEGRATION}/muiTheme`).default || {};
  }
} catch (_) {
  // Nothing to do
}

export default (overrides) => {
  const colors = merge(defaultColors, overrides);
  const metrics = merge(defaultMetrics, overrides);

  return createTheme(merge({
    palette: {
      primary: {
        main: colors.primary,
      },
      secondary: {
        main: colors.secondary,
      },
      error: {
        main: colors.error,
      },
      text: {
        primary: colors.primary,
        secondary: colors.black,
      },
    },
    metrics,
    typography: {
      useNextVariants: true,
      fontFamily: 'Muli, sans-serif',
      fontSize: 16,
      body1: {
        fontSize: 14,
        color: colors.tertiaryText,
        letterSpacing: 0.19,
      },
      body2: {
        color: colors.primary,
        letterSpacing: 0.16,
      },
      subtitle1: {
        color: colors.primary,
        letterSpacing: 0.19,
        fontSize: '16px',
        fontWeight: 'normal',
      },
      h3: {
        fontSize: '14px',
        color: colors.primary,
        letterSpacing: 0.16,
        fontWeight: 'bold',
      },
      h4: {
        fontSize: '13px',
        color: colors.primary,
        letterSpacing: 0.16,
        fontWeight: 'bold',
      },
      h5: {
        fontSize: '20px',
        color: colors.primary,
        letterSpacing: 0.16,
      },
      h2: {
        fontSize: '26px',
      },
    },
    breakpoints: {
      xs: metrics.breakpoint.xs,
      sm: metrics.breakpoint.sm,
      md: metrics.breakpoint.md,
      lg: metrics.breakpoint.lg,
      xl: metrics.breakpoint.xl,
    },
    overrides: {
      MuiFormLabel: {
        root: {
          color: colors.grey,
          '&.Mui-focused': {
            color: colors.secondary,
          },
        },
      },
      MuiInput: {
        root: {
          '& .MuiInputBase-input': {
            color: colors.primary,
          },
          '&.MuiInput-underline.Mui-focused': {
            '& .MuiInputAdornment-root svg': {
              fill: colors.secondary,
            },
            '&:after, &:before, &:hover:not(.Mui-disabled):before': {
              borderBottomColor: colors.secondary,
            },
          },

          '&.MuiInput-underline:after, &.MuiInput-underline:before, &.MuiInput-underline:hover:not(.Mui-disabled):before': {
            borderBottomColor: colors.grey,
          },
        },
      },

      MuiButton: {
        root: {
          backgroundColor: colors.secondary,
          color: colors.buttonText,

          '&.MuiButton-contained': {
            backgroundColor: colors.secondary,
            color: colors.buttonText,
            '&:hover': {
              backgroundColor: colors.hovered,
            },
          },

          '&.MuiButton-outlined': {
            color: colors.secondary,
            borderColor: colors.secondary,
            backgroundColor: 'transparent',
          },
        },
      },
    },
  }, integrationTheme || {}));
};
