import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Tooltip from '@mui/material/Tooltip';
import Badge from '@mui/material/Badge';
import Group from '@mui/icons-material/Group';
import AccessTime from '@mui/icons-material/AccessTime';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MicNone from '@mui/icons-material/MicNone';
import PauseCircleOutline from '@mui/icons-material/PauseCircleOutline';
import PhoneForwarded from '@mui/icons-material/PhoneForwarded';
import Dialpad from '@mui/icons-material/Dialpad';
import { useLocation } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { INCOMING } from '../../call/reducers/callReducer';
import { call, hangup, mute, unmute, hold, unhold, acceptCall, rejectCall } from '../../call/actions/callActions';
import { HangupButton, CallButton, DialpadButton, ReturnToCall } from '../../dialer/components/ContextualButtons';
import { ReturnToCard } from '../../card/components/ContextualButtons';
import colors from '../themes/colors';
import ButtonWithIcon from '../components/ButtonWithIcon';
import AgentState from '../../agent/containers/AgentState';
import StateIndicator from '../../contact/containers/StateIndicator';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${colors.secondGrey};
  height: 70px;
`;

const IncomingContainer = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
`;

const IncomingElement = styled.div`
  margin: 0 auto;
`;

const Part = styled.div`
  width: 40%;
  display: flex;
`;

const ContextualButtonContainer = styled.div`
  margin: -20px auto 0;
`;

const ContextualButton = ({ keypadNumber, isOnCall, actions, location, writingCard }) => {
  const isInCallScene = location.pathname.indexOf('call') !== -1;
  const isInDialerScene = location.pathname.indexOf('dialer') !== -1;

  if (isOnCall) {
    if (isInCallScene) {
      return <HangupButton action={actions.hangup} />;
    }

    return <ReturnToCall />;
  }

  if (writingCard) {
    if (isInCallScene) {
      return <DialpadButton />;
    }

    return <ReturnToCard />;
  }

  if (!!keypadNumber && isInDialerScene) {
    return <CallButton action={() => actions.call(keypadNumber)} />;
  }

  return <DialpadButton />;
};

const LeftPart = ({ agentEnabled, isOnCall, location, muted, held, actions, indirectTransferCall, t }) => {
  const toggleMute = () => actions[muted ? 'unmute' : 'mute']();
  const toggleHold = () => actions[held ? 'unhold' : 'hold']();

  if (isOnCall) {
    return (
      <Part>
        <ButtonWithIcon active={muted} id={muted ? 'tabbar-unmute' : 'tabbar-mute'} onClick={toggleMute} to="#">
          <Tooltip placement="top" title={muted ? t('unmute') : t('mute')}>
            <MicNone />
          </Tooltip>
        </ButtonWithIcon>

        <ButtonWithIcon
          active={held}
          disabled={indirectTransferCall}
          id={held ? 'tabbar-resume' : 'tabbar-hold'}
          onClick={indirectTransferCall ? null : toggleHold}
          to="#"
        >
          <Tooltip placement="top" title={held ? t('unhold') : t('hold')}>
            <PauseCircleOutline />
          </Tooltip>
        </ButtonWithIcon>
      </Part>
    );
  }

  return (
    <Part>
      <ButtonWithIcon active={location.pathname.indexOf('contacts') !== -1} id="go-to-contacts" to="/contacts">
        <Tooltip placement="top" title={t('contacts')}>
          <Group />
        </Tooltip>
      </ButtonWithIcon>

      {agentEnabled && <AgentState />}
    </Part>
  );
};

const RightPart = ({ ownContact, isOnCall, location, indirectTransferCall, t }) => {
  if (isOnCall) {
    return (
      <Part>
        <ButtonWithIcon active={location.pathname.indexOf('transfer') !== -1} data-testid="toolbar-transfer" to="/transfer">
          <Tooltip placement="top" title={t('transfer')}>
            {indirectTransferCall ? <Badge badgeContent={1} color="error"><PhoneForwarded /></Badge> : <PhoneForwarded />}
          </Tooltip>
        </ButtonWithIcon>

        <ButtonWithIcon active={location.pathname.indexOf('dialer') !== -1} id="open-dialer" to="/dialer">
          <Dialpad />
        </ButtonWithIcon>
      </Part>
    );
  }

  return (
    <Part>
      <ButtonWithIcon active={location.pathname.indexOf('call-logs') !== -1} id="go-to-call-logs" to="/call-logs">
        <Tooltip placement="top" title={t('callLogs')}>
          <AccessTime />
        </Tooltip>
      </ButtonWithIcon>

      <ButtonWithIcon active={location.pathname.indexOf('settings') !== -1} id="go-to-settings" to="/settings">
        <StateIndicator contact={ownContact} left={40} top={20} />
        <Tooltip placement="top" title={t('settings')}>
          <AccountCircle />
        </Tooltip>
      </ButtonWithIcon>
    </Part>
  );
};

const InComingCall = ({ locallyAccepted, actions }) => (
  <IncomingContainer>
    <IncomingElement>
      <CallButton action={locallyAccepted ? () => {} : actions.acceptCall} disabled={!!locallyAccepted} />
    </IncomingElement>

    <IncomingElement>
      <HangupButton action={actions.rejectCall} />
    </IncomingElement>
  </IncomingContainer>
);

export const TabBar = ({
  agentEnabled,
  locallyAccepted,
  keypadNumber,
  ringing,
  inCall,
  held,
  muted,
  actions,
  direction,
  writingCard,
  indirectTransferCall,
  ownContact,
  t,
}) => {
  const isOnCall = ringing || inCall;
  const location = useLocation();

  if (ringing && direction === INCOMING) {
    return <InComingCall actions={actions} locallyAccepted={locallyAccepted} />;
  }

  return (
    <Container>
      <LeftPart
        actions={actions}
        agentEnabled={agentEnabled}
        held={held}
        indirectTransferCall={indirectTransferCall}
        isOnCall={isOnCall}
        location={location}
        muted={muted}
        t={t}
      />

      <ContextualButtonContainer>
        <ContextualButton
          actions={actions}
          isOnCall={isOnCall}
          keypadNumber={keypadNumber}
          location={location}
          writingCard={writingCard}
        />
      </ContextualButtonContainer>

      <RightPart
        indirectTransferCall={indirectTransferCall}
        isOnCall={isOnCall}
        location={location}
        ownContact={ownContact}
        t={t}
      />
    </Container>
  );
};

const mapStateToProps = state => ({
  agentEnabled: state.main.agentEnabled,
  writingCard: state.card.schema && state.card.cardId,
  keypadNumber: state.dialer.keypadNumber,
  ringing: state.call.ringing,
  direction: state.call.direction,
  inCall: state.call.inCall,
  held: state.call.held,
  muted: state.call.muted,
  locallyAccepted: state.call.locallyAccepted,
  indirectTransferCall: state.call.indirectTransferCall,
  ownContact: state.contact.ownContact,
  updatedAt: state.contact.updatedAt,
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ call, hangup, mute, unmute, hold, unhold, acceptCall, rejectCall }, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(React.memo(withTranslation('call')(TabBar)));
