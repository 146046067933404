import React from 'react';
import { connect } from 'react-redux';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { ThemeProvider } from 'styled-components';
import merge from 'lodash.merge';

import getMuiTheme from '../themes/muiTheme';
import wazoTheme from '../themes';

const WazoThemeProvider = ({ overrideThemes, children }) => (
  <MuiThemeProvider theme={getMuiTheme(overrideThemes)}>
    <ThemeProvider theme={merge(wazoTheme, overrideThemes)}>
      {children}
    </ThemeProvider>
  </MuiThemeProvider>
);

const mapStateToProps = state => ({
  overrideThemes: state.main.overrideThemes,
});

export default connect(mapStateToProps)(WazoThemeProvider);
