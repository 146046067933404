import React from 'react';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography/Typography';
import IconButton from '@mui/material/IconButton';
import CallIcon from '@mui/icons-material/Call';
import { withTranslation } from 'react-i18next';

import Initials from '../domain/Initials';
import StateIndicator from '../containers/StateIndicator';
import List from '../../main/components/List';
import colors from '../../main/themes/colors';
import Scrollbars from '../../main/components/Scrollbars';

const SEARCH_INPUT_HEIGHT = 80;

const EmptySearch = styled(Typography)`
  padding: 30px 15px;
  text-align: center;
`;

const AvatarWrapper = styled.div`
  position: relative;

  .MuiAvatar-root {
    font-size: 14px;
  }
`;

const StyledAvatar = styled(Avatar)`
  background-color: ${colors.primary} !important;
`;

const CallButton = styled(IconButton)`
  margin-right: 15px !important;
`;

const style = {
  height: `calc(100% - ${SEARCH_INPUT_HEIGHT}px)`,
  padding: '0 15px',
};

const SearchResults = ({ t, results, searchAction, SearchResultsButtons }) => {
  const navigate = useNavigate();

  if (!results.length) {
    return <EmptySearch>{t('emptySearch')}</EmptySearch>;
  }

  const call = number => searchAction ? searchAction(number) : navigate(`/dialer?call=${encodeURIComponent(number)}`);

  return (
    <Scrollbars id="search-results" style={style}>
      <List>
        {results.map(contact => contact.numbers.map(({ number }) => (
          <ListItem key={`${contact.uuid || contact.sourceId}-${number}`}>
            <AvatarWrapper>
              <StateIndicator contact={contact} left={30} top={30} />
              <ListItemAvatar>
                <StyledAvatar>
                  {Initials.parseFromContact(contact)}
                </StyledAvatar>
              </ListItemAvatar>
            </AvatarWrapper>
            <ListItemText primary={contact.name} secondary={number} />
            <ListItemSecondaryAction>
              {SearchResultsButtons ? <SearchResultsButtons contact={contact} /> : (
                <CallButton className="call-button" color="secondary" edge="end" onClick={() => call(number)}>
                  <CallIcon fontSize="small" />
                </CallButton>
              )}
            </ListItemSecondaryAction>
          </ListItem>
        )))}
      </List>
    </Scrollbars>
  );
};

export default withTranslation('contact')(SearchResults);
