export default {
  username: 'Username',
  password: 'Password',
  server: 'Domain configuration',

  next: 'Next',
  login: 'Login',
  logout: 'Logout',
  wrongCredentials: 'Wrong login or password',
  errorOccurred: 'An error occurred',
  authFailure: 'Authentication failure',
  noAuthorization: 'This account doesn\'t have access to the application',
  ldapConnection: 'Log in with LDAP',
  ldapLogin: 'Log in with LDAP',
  domainName: 'LDAP domain name',
  noDomainName: 'A domain name must be provided to log in with LDAP',
  wrongDomainName: 'Couldn\'t find a matching LDAP domain, please verify its name',

  userState: 'State',
  userStatus: 'Status',
  available: 'Available',
  doNotDisturb: 'Do not disturb',
  notAvailable: 'Not available',
  addStatus: 'Add a status...',

  initialLoading: 'Loading...',

  createAccount: 'Not a Wazo customer yet?<br />Visit <a href="https://wazo.io/contact" rel="noreferrer" target="_blank">https://wazo.io/contact/</a> to get started',
  notConnectedToWDA: 'The EUC Wazo Desktop application doesn\'t seem active.',
};
