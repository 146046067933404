export const getLocalStorageItem = name => {
  try {
    return localStorage.getItem(name);
  } catch (_) {
    // Avoid Failed to read 'localStorage' from 'Window': Access denied for this document issues
  }
  return null;
};

export const setLocalStorageItem = (name, value) => {
  try {
    return localStorage.setItem(name, value);
  } catch (_) {
    // Avoid Failed to read 'localStorage' from 'Window': Access denied for this document issues
  }
  return null;
};

export const clearLocalStorage = () => {
  try {
    localStorage.clear();
  } catch (_) {
    // Avoid Failed to read 'localStorage' from 'Window': Access denied for this document issues
  }
};
