export const SAVE_CARD_REQUEST = 'card/SAVE_CARD_REQUEST';
export const SKIP_CARD = 'card/SKIP_CARD';
export const STORE_CARD_ID = 'card/STORE_CARD_ID';
export const CREATE_OR_UPDATE_CARD_REQUEST = 'card/CREATE_OR_UPDATE_CARD_REQUEST';
export const CREATE_OR_UPDATE_CARD_SUCCESS = 'card/CREATE_OR_UPDATE_CARD_SUCCESS';
export const UPDATE_CARD = 'card/UPDATE_CARD';
export const CARD_SET_VALUE = 'card/CARD_SET_VALUE';
export const ON_OPTIONS_FETCHED = 'card/ON_OPTIONS_FETCHED';
export const ON_OPTIONS_FOUND = 'card/ON_OPTIONS_FOUND';
export const DISPLAY_LINKED_OPTION = 'card/DISPLAY_LINKED_OPTION';
export const SEARCH_OPTIONS = 'card/SEARCH_OPTIONS';
export const ON_SAVING_CARD = 'card/ON_SAVING_CARD';
export const ON_SAVED_CARD = 'card/ON_SAVED_CARD';
export const UPDATE_FORM_SCHEMA = 'card/UPDATE_FORM_SCHEMA';

export const saveCard = (cardContent, callSession) => ({
  type: SAVE_CARD_REQUEST,
  payload: { cardContent, callSession },
});

export const skipCard = () => ({
  type: SKIP_CARD,
});

export const storeCardId = id => ({
  type: STORE_CARD_ID,
  payload: { id },
});

export const createOrUpdateCard = (cardId, cardContent, callSession) => ({
  type: CREATE_OR_UPDATE_CARD_REQUEST,
  payload: { cardId, cardContent, callSession },
});

export const cardCreatedOrUpdated = () => ({
  type: CREATE_OR_UPDATE_CARD_SUCCESS,
});

export const setValue = (name, value) => ({
  type: CARD_SET_VALUE,
  payload: { name, value },
});

export const updateCard = card => ({
  type: UPDATE_CARD,
  payload: card,
});

export const updateFormSchema = (schema, uiSchema) => ({
  type: UPDATE_FORM_SCHEMA,
  payload: { schema, uiSchema },
});

export const onOptionsFetched = (options, fieldId) => ({
  type: ON_OPTIONS_FETCHED,
  payload: { options, fieldId },
});

export const onOptionsFound = (options, fieldId) => ({
  type: ON_OPTIONS_FOUND,
  payload: { options, fieldId },
});

export const displayLinkedOption = linkedOptionId => ({
  type: DISPLAY_LINKED_OPTION,
  payload: { linkedOptionId },
});

export const searchOptions = (query, fieldId) => ({ type: SEARCH_OPTIONS, payload: { query, fieldId } });

export const onSavingCard = () => ({ type: ON_SAVING_CARD });

export const onSavedCard = () => ({ type: ON_SAVED_CARD });
