import { takeEvery } from 'redux-saga/effects';
import i18n from 'i18next';
import Wazo from '@wazo/sdk/lib/simple';

import { UPDATE_LANGUAGE } from '../actions/mainActions';
import { setLocalStorageItem } from '../utils/localstorage';

const logger = Wazo.IssueReporter.loggerFor('main-saga');

function* updateLanguage({ payload: { language } }) {
  logger.info('changing language', { language });

  i18n.changeLanguage(language);
  setLocalStorageItem('language', language);
}

export default [
  takeEvery(UPDATE_LANGUAGE, updateLanguage),
];
