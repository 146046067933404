import React from 'react';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { Typography } from '@mui/material';
import { MuiForm5 as Form } from '@rjsf/material-ui';

import colors from '../../main/themes/colors';
import CloseButton from '../../main/components/CloseButton';

const Container = styled.div`
  position: relative;
  width: calc(80% - 40px);
  height: 80%;
  margin: 30px auto;
  padding: 20px;
  background-color: ${colors.secondGrey};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ValidateButton = styled(Button)`
  && {
    width: 120px;
    margin: 10px auto 0;
    border-radius: 28px;
    display: block;
  }
`;

const NewOptionForm = ({ callSession, form, onAddOption, setModalOpen, t }) => {
  const { number, displayName } = callSession || {};
  let names = [];
  if (callSession) {
    names = number !== displayName ? displayName?.split(' ') : [] || [];
  }
  const formData = {
    number,
    phone: number,
    phoneNumber: number,
    firstName: names[0] || '',
    lastName: names.slice(1).join(' '),
  };

  const performSubmit = ({ formData: submittedFormData }) => {
    onAddOption(submittedFormData);
  };

  return (
    <Container component="form">
      <CloseButton onClick={() => setModalOpen(false)}>
        <CloseIcon />
      </CloseButton>

      <Typography align="center" variant="h2">{t('addOption')}</Typography>

      <Form
        formData={formData}
        noHtml5Validate
        onSubmit={performSubmit}
        schema={form.schema}
        showErrorList={false}
        uiSchema={form.uiSchema || {}}
      >
        <ValidateButton type="submit" variant="outlined">
          {t('validateNewOption')}
        </ValidateButton>
      </Form>
    </Container>
  );
};

const mapStateToProps = state => ({
  callSession: state.card.callSession,
});

export default connect(mapStateToProps)(withTranslation('card')(NewOptionForm));
